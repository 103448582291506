import {
  useUserSelf,
  useUserShowSelf,
  useUserUpdate,
  useUserUpdateSelf,
} from "@gocardless/api/dashboard/user";
import {
  AmlPersonResource,
  UserUpdateResponseBody,
} from "@gocardless/api/dashboard/types";

import { UseSetupPageCallbacks } from "src/components/routes/Setup/routing/types";
import { usePrimaryCreditor } from "src/libraries/creditor/hooks";
import { useOrganisation } from "src/libraries/organisation/hooks";
import { useIsImpersonation } from "src/queries/user";

export interface PaobVerification {
  loaded: boolean;
  gcSasVerificationEnabled: boolean;
  showWarning: boolean;
  isPrimaryUser: boolean;
  userAmlEntity?: AmlPersonResource;
  updateUserAmlEntity: (
    aml_entity: AmlPersonResource,
    authority_confirmed?: boolean
  ) => Promise<UserUpdateResponseBody | undefined>;
}

export function usePaobVerification({
  onSuccess = () => {},
  onError = () => {},
}: UseSetupPageCallbacks = {}): PaobVerification {
  const creditor = usePrimaryCreditor();
  const { data: userData, revalidate } = useUserShowSelf();
  const [updateUser] = useUserUpdateSelf({
    onSuccess: () => {
      revalidate().then(() => onSuccess());
    },
    onError,
  });
  const gcSasVerificationEnabled =
    !!creditor?.gc_sas_verifications_enabled &&
    creditor?.gc_segregation_entity === "gc_sas";

  // GC Admin Impersonation support
  const organisation = useOrganisation();
  const isImpersonation = useIsImpersonation();
  const primaryUserId = organisation?.links?.primary_user;
  const { data: primaryUserData, revalidate: primaryRevalidate } = useUserSelf(
    primaryUserId || null,
    isImpersonation
  );
  const [updatePrimaryUser] = useUserUpdate(primaryUserId || "", {
    onSuccess: () => {
      primaryRevalidate().then(() => onSuccess());
    },
    onError,
  });

  const updateUserAmlEntity = (
    aml_entity: AmlPersonResource,
    authority_confirmed?: boolean
  ) =>
    isImpersonation
      ? updatePrimaryUser({ aml_entity, authority_confirmed })
      : updateUser({ aml_entity, authority_confirmed });
  return {
    loaded: !!userData && !!creditor,
    gcSasVerificationEnabled: gcSasVerificationEnabled,
    showWarning: gcSasVerificationEnabled,
    isPrimaryUser: !!userData?.users?.primary,
    userAmlEntity: isImpersonation
      ? primaryUserData?.users?.aml_entity
      : userData?.users?.aml_entity,
    updateUserAmlEntity,
  };
}
