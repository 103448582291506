import {
  DayOfWeek,
  IntervalUnit,
  Month,
} from "@gocardless/api/dashboard/types";

import { Currency } from "src/common/currencies";
import { CUSTOM_INTERVAL } from "src/components/form/IntervalUnitField";
import { FormType } from "src/components/routes/RequestPayment/InstalmentTemplates/Create/useInstalmentTemplateCreateForm";

export enum Schedule {
  ASAP = "asap",
  SPECIFIED = "specified",
}

export enum Timeframe {
  NumberOfPayment = "number_of_payment",
  UntilFurtherNotice = "until_further_notice",
}

export enum PaymentType {
  Mandate = "mandate",
  Plan = "plan",
  OneOff = "oneoff",
  Instalment = "instalment",
}

export interface PlanFormType {
  name: string;
  amount: number;
  count?: number;
  currency: Currency;
  day_of_month?: string;
  day_of_week?: DayOfWeek;
  interval?: number;
  interval_unit: IntervalUnit | typeof CUSTOM_INTERVAL;
  month?: Month;
  redirect_url?: string;
  custom_interval_unit?: IntervalUnit;
  schedule: Schedule;
  timeframe: Timeframe;
  payment_reference?: string;
}

export enum OneOffPaymentTypes {
  INSTANT_BANK_PAY = "ibp",
  DIRECT_DEBT = "dd",
}

export interface OneOffFormType {
  name: string;
  amount: number;
  payment_type?: OneOffPaymentTypes;
  currency: Currency;
  redirect_url?: string;
  create_dd_along_with_ibp?: "true" | "false";
  payments_require_approval?: boolean;
}

export type InstalmentFormType = FormType;

export enum LowRiskOnboardingVariant {
  Disabled = "disabled",
  Eligible = "eligible",
  NotEligible = "not_eligible",
}

export interface ProgressBarSteps {
  stepNumber: number;
  totalSteps: number;
}
