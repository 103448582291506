import { OrganisationResource } from "@gocardless/api/dashboard/types";

import { usePackageSelectionSkipped } from "../v3/usePackageSelectionSkipped/usePackageSelectionSkipped";

interface usePartnerMerchantShouldSkipPrimaryPackageSelectionProps {
  organisation: OrganisationResource | undefined;
}

export const usePartnerMerchantShouldSkipPrimaryPackageSelection = ({
  organisation,
}: usePartnerMerchantShouldSkipPrimaryPackageSelectionProps) => {
  const isPartnerMerchant = organisation?.is_partner_merchant;

  const { packageSelectionSkipped } = usePackageSelectionSkipped(
    organisation?.id
  );

  const shouldSkipSecondary =
    (organisation && !isPartnerMerchant) ||
    (isPartnerMerchant &&
      (!packageSelectionSkipped ||
        !!organisation.organisation_preferences
          ?.merchant_has_requested_package));

  const shouldSkipPrimary = isPartnerMerchant && packageSelectionSkipped;

  return {
    shouldSkipPrimary: !!shouldSkipPrimary,
    shouldSkipSecondary: !!shouldSkipSecondary,
  };
};
