import { get } from "lodash";
import { HTTPError } from "@gocardless/api/utils/api";
import { CreditorType } from "@gocardless/api/dashboard/types";

import { useSetupData } from "../common/hooks/useSetupData";
import { UseSetupPage } from "../routing/types";

import { CountryCodes } from "src/common/country";
import { useOrganisation } from "src/libraries/organisation/hooks";
import { Route } from "src/common/routing";

export interface UseBusinessCategory extends UseSetupPage {
  description: string;
  merchantCategoryCode: string;
  creditorType: CreditorType | null;
  online_presence_url?: string;
  geo: string | null;
  submitBusinessCategory: (data: BusinessCategoryConfig) => Promise<void>;
}

export interface BusinessCategoryConfig {
  description?: string;
  merchantCategoryCode?: string;
  industry?: string;
  online_presence_url?: string;
}

export const useBusinessCategory = ({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: () => void;
  onError?: (error: HTTPError) => void;
} = {}): UseBusinessCategory => {
  const { creditor, creditorDetails, updateCreditorDetail } = useSetupData(
    onSuccess,
    onError
  );

  const description = get(creditorDetails, "detail.description", "") as string;
  const merchantCategoryCode = get(
    creditorDetails,
    "detail.merchant_category_code",
    ""
  ) as string;
  const online_presence_url = get(
    creditorDetails,
    "detail.online_presence_url",
    ""
  ) as string;

  const countryCode = get(creditorDetails, "detail.country_code");
  const creditorType = get(creditorDetails, "creditor_type");
  const companyType = get(creditorDetails, "detail.company_type");

  const organisation = useOrganisation();

  const isUSLimitedCompanyMerchant =
    countryCode === CountryCodes.US && creditorType === CreditorType.Company;

  const completed =
    creditorType === CreditorType.Charity
      ? !!description
      : !!(description && merchantCategoryCode);

  const submitBusinessCategory = async (data: BusinessCategoryConfig) => {
    updateCreditorDetail({
      creditor_type: creditorDetails?.creditor_type,
      detail: {
        description: data.description,
        online_presence_url: data.online_presence_url,
        merchant_category_code: data.merchantCategoryCode,
        ...(isUSLimitedCompanyMerchant && {
          company_type: companyType,
        }),
      },
    });
  };

  const skip = !!organisation?.links?.payment_provider;

  return {
    loaded: !!creditorDetails,
    completed,
    route: Route.BusinessCategory,
    skip,
    description: description,
    merchantCategoryCode: merchantCategoryCode,
    online_presence_url: online_presence_url,
    creditorType: creditorType ?? null,
    geo: creditor?.geo ?? null,
    submitBusinessCategory,
  };
};
