import { useState } from "react";
import {
  useRequiredActionAttachDocument,
  useRequiredActionList,
} from "@gocardless/api/dashboard/required-action";
import { useVerificationDocumentTokensCreate } from "@gocardless/api/dashboard/verification-document-tokens";
import {
  Document,
  RequiredActionsName,
  RequiredActionsStatus,
  DocumentSide,
  UploadedDocumentType,
} from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";

import { UseSetupPage } from "../routing/types";

import { errorNotificationHandler } from "src/common/notifications/notificationErrorHandlers";
import { useToastNotification } from "src/hooks/useToastNotification";
import { Route } from "src/common/routing";

export interface UseVerifyBankDocument extends UseSetupPage {
  status?: RequiredActionsStatus;
  uploading: boolean;
  uploadDocument: (body?: BodyInit) => void;
}

export const useVerifyBankDocument = (): UseVerifyBankDocument => {
  const [uploading, setUploading] = useState(false);
  const { triggerSuccessNotification, triggerErrorNotification } =
    useToastNotification();

  const { data, revalidate } = useRequiredActionList();
  const requiredActionList = data?.required_actions || [];
  const bankDocRequiredAction = requiredActionList.find(
    (requiredAction) =>
      requiredAction.name === RequiredActionsName.ProvideBankStatement
  );
  const [submitDocument] = useRequiredActionAttachDocument(
    bankDocRequiredAction?.id || "",
    {
      onSuccess: (_response) => {
        revalidate();
        triggerSuccessNotification({
          title: <Trans id="Success">Success!</Trans>,
          message: <Trans>Your document has been uploaded successfully</Trans>,
        });
        setUploading(false);
      },
      onError: (error) => {
        setUploading(false);
        errorNotificationHandler(error, triggerErrorNotification);
      },
    }
  );
  const [uploadFile] = useVerificationDocumentTokensCreate({
    onSuccess: (response) => {
      const token = response?.files?.token || "";
      const document: Document = {
        token,
        side: DocumentSide.Front,
        document_type: UploadedDocumentType.BankStatement,
      };
      submitDocument([document]);
    },
    onError: (error) => {
      setUploading(false);
      errorNotificationHandler(error, triggerErrorNotification);
    },
  });

  const uploadDocument = (file?: BodyInit) => {
    uploadFile(file);
    setUploading(true);
  };
  return {
    completed:
      !!bankDocRequiredAction &&
      bankDocRequiredAction.status !== RequiredActionsStatus.Pending,
    status: bankDocRequiredAction?.status || undefined,
    loaded: !!data,
    route: Route.VerifyBankDocument,
    skip: !bankDocRequiredAction,
    uploadDocument,
    uploading,
  };
};
