import {
  Box,
  H2,
  P,
  ColorPreset,
  ResponsiveValue,
  TypeScale,
} from "@gocardless/flux-react";
import { AppearanceProps } from "@gocardless/flux-react/types/latest/layout/Box";

interface PageTitleProps {
  title: string;
  description?: string | React.ReactNode;
  variant?: PageTitleVariant;
  additionalStyles?: AppearanceProps;
}

export enum PageTitleVariant {
  Intro = "intro",
  Setup = "setup",
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  description,
  variant = PageTitleVariant.Setup,
  additionalStyles,
}) => {
  const headerSize: Record<PageTitleVariant, ResponsiveValue<TypeScale>> = {
    [PageTitleVariant.Intro]: 7,
    [PageTitleVariant.Setup]: [7, 8, null, null, 9],
  };
  const bodySize: Record<PageTitleVariant, ResponsiveValue<TypeScale>> = {
    [PageTitleVariant.Intro]: 3,
    [PageTitleVariant.Setup]: [3, null, null, null, 4],
  };

  return (
    <Box spaceAbove={3} spaceBelow={4} {...additionalStyles}>
      <H2 size={headerSize[variant]} spaceBelow={1}>
        {title}
      </H2>
      {typeof description === "string" ? (
        <P size={bodySize[variant]} color={ColorPreset.TextOnLight_02}>
          {description}
        </P>
      ) : (
        <div>{description}</div>
      )}
    </Box>
  );
};

export default PageTitle;
