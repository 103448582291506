import { ReactNode } from "react";

import { CountryCodes } from "src/common/country";

// @todo move this to common

export enum PackageNamev3 {
  Standard = "standard",
  Pro = "pro",
  Advanced = "advanced",
}

export enum PackageType {
  Standard = "standard",
  StandardPendingPlus = "standard_pending_plus",
  StandardPendingPro = "standard_pending_pro",
  StandardPendingEnterprise = "standard_pending_enterprise",
  Plus = "plus",
  PlusPendingPro = "plus_pending_pro",
  PlusPendingEnterprise = "plus_pending_enterprise",
  Pro = "pro",
  ProPendingEnterprise = "pro_pending_enterprise",
  Enterprise = "enterprise",
  Custom = "custom",
}

export enum PackageNames {
  Standard = "Standard",
  Plus = "Plus",
  Pro = "Pro",
  Enterprise = "Enterprise",
  Custom = "Custom",
}

export const packageNameToDisplay: Record<PackageType, PackageNames> = {
  [PackageType.Standard]: PackageNames.Standard,
  [PackageType.StandardPendingPlus]: PackageNames.Plus,
  [PackageType.StandardPendingPro]: PackageNames.Pro,
  [PackageType.StandardPendingEnterprise]: PackageNames.Enterprise,
  [PackageType.Plus]: PackageNames.Plus,
  [PackageType.PlusPendingPro]: PackageNames.Pro,
  [PackageType.PlusPendingEnterprise]: PackageNames.Enterprise,
  [PackageType.Pro]: PackageNames.Pro,
  [PackageType.ProPendingEnterprise]: PackageNames.Enterprise,
  [PackageType.Enterprise]: PackageNames.Enterprise,
  [PackageType.Custom]: PackageNames.Custom,
};

export type PackagePricePerGeo = {
  [code in CountryCodes]?: {
    plus: string;
    pro: string;
  };
};

export type PackageTypeDetails = {
  [type in PackageType]?: ReactNode[];
};

export type PackageInfoInterface = {
  [code in CountryCodes]?: PackageTypeDetails;
};

export type PaymentWorkingDaysNotice = {
  [code in CountryCodes]?: ReactNode;
};

export type TextPerGeoPackageType = {
  [code in CountryCodes]?: {
    plus: ReactNode[];
    pro: ReactNode[];
  };
};
