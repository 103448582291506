import { CreditorType } from "@gocardless/api/dashboard/types";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";

import { UseSetupPage, UseSetupPageCallbacks } from "../routing/types";

import { Route } from "src/common/routing";
import { usePaobVerification } from "src/components/routes/Setup/common/hooks/usePaobVerification";
import { useSetupData } from "src/components/routes/Setup/common/hooks/useSetupData";
import { useGcSasRemediation } from "src/components/routes/Setup/common/hooks/useGcSasRemediation";
import { useIsImpersonation } from "src/queries/user";

export interface PersonActingOnBehalfOfType {
  given_name?: string;
  family_name?: string;
  date_of_birth?: string | null;
  place_of_birth?: string | null;
}

export interface PersonActingOnBehalfOfConfig {
  person: PersonActingOnBehalfOfType;
  authority_confirmed?: boolean;
}

export interface PersonActingOnBehalfOf extends UseSetupPage {
  personActingOnBehalfOf: PersonActingOnBehalfOfConfig;
  submitPersonActingOnBehalfOf: (data: PersonActingOnBehalfOfConfig) => void;
  countryCode: string;
  shouldConfirmAuthority: boolean;
}

const SUPPORTED_CREDITOR_TYPES = [
  CreditorType.Company,
  CreditorType.Partnership,
];

export function usePersonActingOnBehalfOf({
  onSuccess = () => {},
  onError = () => {},
}: UseSetupPageCallbacks = {}): PersonActingOnBehalfOf {
  const { creditorDetails } = useSetupData();
  const { data: userData } = useUserShowSelf();
  const isImpersonation = useIsImpersonation();
  const {
    loaded: paobVerificationLoaded,
    gcSasVerificationEnabled,
    isPrimaryUser,
    userAmlEntity,
    updateUserAmlEntity,
  } = usePaobVerification({ onSuccess, onError });
  const { loaded: gcSasRemediationLoaded, gcSasRemediationPending } =
    useGcSasRemediation();

  const creditor_type = creditorDetails?.creditor_type;
  const countryCode = creditorDetails?.detail?.country_code ?? "";
  const creditorTypeSupported =
    creditor_type && SUPPORTED_CREDITOR_TYPES.includes(creditor_type);
  const personActingOnBehalfOf: PersonActingOnBehalfOfConfig = isImpersonation
    ? {
        person: { ...userAmlEntity },
      }
    : {
        person: {
          given_name: userData?.users?.given_name,
          family_name: userData?.users?.family_name,
          ...userAmlEntity,
        },
      };

  const submitPersonActingOnBehalfOf = (data: PersonActingOnBehalfOfConfig) => {
    updateUserAmlEntity(
      { ...data.person, country_code: countryCode },
      data.authority_confirmed
    );
  };

  return {
    loaded:
      !!creditorDetails && paobVerificationLoaded && gcSasRemediationLoaded,
    completed: !!userAmlEntity?.place_of_birth,
    skip: !(gcSasVerificationEnabled && creditorTypeSupported && isPrimaryUser),
    route: Route.PersonActingOnBehalfOf,
    personActingOnBehalfOf,
    submitPersonActingOnBehalfOf,
    countryCode,
    shouldConfirmAuthority: gcSasRemediationPending,
  };
}
