import {
  CreditorDetailUpdateRequestBody,
  CreditorType,
} from "@gocardless/api/dashboard/types";
import { HTTPError } from "@gocardless/api/utils/api";

import { UseSetupPage } from "../routing/types";
import { useSetupData } from "../common/hooks/useSetupData";
import { useComplete } from "../common/validators/useComplete";
import { SetupPages } from "../common/config/types";
import {
  getTrustOwners,
  mapCountryCodeToUbo,
  TrustOwners,
} from "../common/builders/trust-owners";

import { CountryCodes } from "src/common/country";
import { Route } from "src/common/routing";
export interface UseTrustOwners extends UseSetupPage {
  trustOwners: TrustOwners;
  submitTrustOwners: (data: TrustOwners) => void;
  creditorType: CreditorType;
  geo: CountryCodes;
}

const isSupported = (creditorType: CreditorType, geo: string) => {
  if (creditorType === CreditorType.Trust) {
    return true;
  }
  // supported for German Partnership merchants
  if (creditorType === CreditorType.Partnership && geo === CountryCodes.DE) {
    return true;
  }
  return false;
};

export function useTrustOwners({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: () => void;
  onError?: (error: HTTPError) => void;
} = {}): UseTrustOwners {
  const { creditor, creditorDetails, updateCreditorDetail } = useSetupData(
    onSuccess,
    onError
  );
  const { isListComplete } = useComplete(SetupPages.TRUST_OWNERS);

  const trustOwners = getTrustOwners(creditorDetails);
  const creditorType = creditor?.creditor_type ?? CreditorType.Company;
  const geo = (creditor?.geo as CountryCodes) ?? CountryCodes.GB;
  const submitTrustOwners = (data: TrustOwners) => {
    const put: CreditorDetailUpdateRequestBody = {
      creditor_type: creditorType,
      detail: {
        ultimate_beneficial_owners: mapCountryCodeToUbo(
          data.ultimate_beneficial_owners,
          geo
        ),
        ultimate_beneficial_owners_self_declared: true,
      },
    };
    updateCreditorDetail(put);
  };

  return {
    loaded: !!creditorDetails,
    skip: !isSupported(creditorType, geo),
    completed: isListComplete(
      creditorType,
      trustOwners.ultimate_beneficial_owners
    ),
    route: Route.TrustOwners,
    trustOwners,
    submitTrustOwners,
    creditorType,
    geo,
  };
}
