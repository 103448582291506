import { ReactElement } from "react";
import { FieldName, useFormContext } from "react-hook-form";
import {
  Box,
  Checkbox,
  ColorPreset,
  Field,
  Text,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import {
  Field as Config,
  FieldArrayProps,
} from "src/components/routes/Setup/common/config/types";
import { AboutYouConfig } from "src/components/routes/Setup/about-you/useAboutYou";

type PartnerIsUboFieldProps = {
  defaultChecked: boolean;
  labelElement?: ReactElement;
} & FieldArrayProps;

const PartnerIsUboField: React.FC<PartnerIsUboFieldProps> = ({
  defaultChecked,
  index,
  labelElement,
}) => {
  const { register } = useFormContext<AboutYouConfig>();
  const fieldPath = `partners[${index}].is_ubo`;
  return (
    <Field>
      <Box
        bg={ColorPreset.BackgroundLight_03}
        borderWidth={1}
        borderColor={ColorPreset.BorderOnLight_03}
        borderRadius={1}
        gutterH={2}
        gutterV={2}
      >
        <Checkbox
          {...register(fieldPath as FieldName<object>)}
          id={fieldPath}
          defaultChecked={defaultChecked}
          description={
            <Text color={ColorPreset.TextOnLight_02}>
              <Trans id="setup.business-directors.is-ubo.desc">
                This partner owns or controls 25% or more of this business.
              </Trans>
            </Text>
          }
        >
          {labelElement ?? (
            <Trans id="setup.business-directors.is-ubo.ultimate-beneficial-owner">
              Ultimate beneficial owner
            </Trans>
          )}
        </Checkbox>
      </Box>
    </Field>
  );
};

const PartnerIsBusinessOwnerField: React.FC<PartnerIsUboFieldProps> = (
  props
) => (
  <PartnerIsUboField
    {...props}
    labelElement={
      <Trans id="setup.business-directors.is-ubo.business-owner">
        Business Owner
      </Trans>
    }
  />
);

export const OwnerConfig: Config = {
  name: "is_ubo",
  displayName: "Is Business Owner",
  required: false,
  component: PartnerIsBusinessOwnerField,
};

export const uboConfig: Config = {
  name: "is_ubo",
  displayName: "Is Business Owner",
  required: false,
  component: PartnerIsUboField,
};

export default PartnerIsUboField;
