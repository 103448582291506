import { FC, ReactElement } from "react";
import { P, PlainLink } from "@gocardless/flux-react";

import { useI18n, UserLocale } from "./i18n";

import { usePrimaryCreditor } from "src/libraries/creditor/hooks";

const GcSasFooterText: Record<UserLocale, ReactElement> = {
  [UserLocale.DE_DE]: (
    <P>
      GoCardless SAS (7 rue de Madrid, 75008. Paris, Frankreich &ndash;
      Unternehmensregisternummer 834 422 180, R.C.S. PARIS), Stammkapital
      6.000.000 EUR, ist von der ACPR (französische Finanzaufsichtsbehörde) mit
      dem Bank Code (CIB) 17118 für die Erbringung von Zahlungsdienstleistungen
      zugelassen.
    </P>
  ),
  [UserLocale.EN_GB]: (
    <P>
      GoCardless SAS (7 rue de Madrid, 75008. Paris, France &ndash; company
      registration number 834 422 180, R.C.S. PARIS), share capital 6 000 000
      EUR, is authorised by the ACPR (French Prudential Supervision and
      Resolution Authority), Bank Code (CIB) 17118, for the provision of payment
      services.
    </P>
  ),
  [UserLocale.EN_US]: (
    <P>
      GoCardless SAS (7 rue de Madrid, 75008. Paris, France &ndash; company
      registration number 834 422 180, R.C.S. PARIS), share capital 6 000 000
      EUR, is authorised by the ACPR (French Prudential Supervision and
      Resolution Authority), Bank Code (CIB) 17118, for the provision of payment
      services.
    </P>
  ),
  [UserLocale.ES_ES]: (
    <P>
      GoCardless SAS (7 rue de Madrid, 75008. París, Francia &ndash; número de
      registro de empresa 834 422 180, R.C.S. PARIS), capital social 6 000 000
      EUR, está autorizada por la ACPR (Autoridad francesa de supervisión
      prudencial y de resolución), Código bancario (CIB) 17118, para la
      prestación de servicios de pago.
    </P>
  ),
  [UserLocale.FR_FR]: (
    <P>
      GoCardless SAS (7 rue de Madrid, 75008 Paris, France &ndash; immatriculée
      au R.C.S. de PARIS sous le numéro 834 422 180), au capital de 6 000 000
      EUR, est autorisée par l&apos;ACPR (Autorité française de Contrôle
      Prudentiel et de Résolution), Code bancaire (CIB) 17118, pour la
      fourniture de services de paiement.
    </P>
  ),
};

const GcIncFooterText: Record<UserLocale, ReactElement> = {
  [UserLocale.DE_DE]: (
    <P>
      GoCardless Inc. (111 Congress Avenue, Suite 500, Austin, TX 78701 &ndash;
      eine Delaware Corporation, NMLS ID 2123932) ist ein von FinCEN
      registriertes Gelddienstleistungsunternehmen mit der Registernummer
      31000261158426 und in bestimmten US-Bundesstaaten ein lizenzierter
      Zahlungsmittler. Weitere Informationen zu den US-Lizenzen und
      Kontaktinformationen der Aufsichtsbehörden von GoCardless Inc. finden Sie
      <PlainLink
        href="https://gocardless.com/en-us/legal/usa-licenses/"
        target="_blank"
        textDecoration="underline"
      >
        hier
      </PlainLink>
    </P>
  ),
  [UserLocale.EN_GB]: (
    <P>
      GoCardless Inc. (111 Congress Avenue, Suite 500, Austin, TX 78701 &ndash;
      a Delaware Corporation, NMLS ID 2123932), is a FinCEN-registered money
      services business, registration number 31000261158426, and a licensed
      money transmitter in certain U.S. states. You can find further information
      about GoCardless Inc.&apos;s U.S. licenses and regulators&apos; contact
      information{" "}
      <PlainLink
        href="https://gocardless.com/en-us/legal/usa-licenses/"
        target="_blank"
        textDecoration="underline"
      >
        here
      </PlainLink>
      .
    </P>
  ),
  [UserLocale.EN_US]: (
    <P>
      GoCardless Inc. (111 Congress Avenue, Suite 500, Austin, TX 78701 &ndash;
      a Delaware Corporation, NMLS ID 2123932), is a FinCEN-registered money
      services business, registration number 31000261158426, and a licensed
      money transmitter in certain U.S. states. You can find further information
      about GoCardless Inc.&apos;s U.S. licenses and regulators&apos; contact
      information{" "}
      <PlainLink
        href="https://gocardless.com/en-us/legal/usa-licenses/"
        target="_blank"
        textDecoration="underline"
      >
        here
      </PlainLink>
      .
    </P>
  ),
  [UserLocale.ES_ES]: (
    <P>
      GoCardless Inc. (111 Congress Avenue, Suite 500, Austin, TX 78701 &ndash;
      una sociedad de Delaware, NMLS ID 2123932), es una empresa de servicios
      monetarios registrada en FinCEN, número de registro 31000261158426, y una
      entidad transmisora de dinero con licencia en algunos estados de EE. UU.
      Puede encontrar más información sobre las licencias de GoCardless Inc. en
      EE. UU. y la información de contacto de los reguladores
      <PlainLink
        href="https://gocardless.com/en-us/legal/usa-licenses/"
        target="_blank"
        textDecoration="underline"
      >
        aquí
      </PlainLink>
      .
    </P>
  ),
  [UserLocale.FR_FR]: (
    <P>
      GoCardless Inc. (111 Congress Avenue, Suite 500, Austin, TX 78701,
      États-Unis &ndash; une société du Delaware, NMLS ID 2123932), est une
      entreprise de services monétaires immatriculée auprès du FinCEN sous le
      numéro 31000261158426, et une société de transfert de fonds autorisée dans
      certains États américains. Vous trouverez plus d&apos;informations sur les
      agréments américains de GoCardless Inc. et les coordonnées des régulateurs
      <PlainLink
        href="https://gocardless.com/en-us/legal/usa-licenses/"
        target="_blank"
        textDecoration="underline"
      >
        ici
      </PlainLink>
      .
    </P>
  ),
};

const GcLtdAustraliaFooterText: Record<UserLocale, ReactElement> = {
  [UserLocale.DE_DE]: (
    <P>
      GoCardless Ltd (GoCardless Ltd, 10-20 Gwynne St, Cremorne, VIC 3121,
      Australien &ndash; Handelsregisternummer 07495895) ist als ausländisches
      Unternehmen in Australien, ABN 17 606 261 74, registriert und besitzt eine
      australische Finanzdienstleistungslizenz (AFSL) mit der Nummer 478976.
    </P>
  ),
  [UserLocale.EN_GB]: (
    <P>
      GoCardless Ltd (10-20 Gwynne St, Cremorne, VIC 3121, Australia &ndash;
      company registration number 07495895) is registered as a foreign company
      in Australia, ABN 17 606 261 74, and holds an Australian Financial
      Services licence (AFSL), number 478976.
    </P>
  ),
  [UserLocale.EN_US]: (
    <P>
      GoCardless Ltd (10-20 Gwynne St, Cremorne, VIC 3121, Australia &ndash;
      company registration number 07495895) is registered as a foreign company
      in Australia, ABN 17 606 261 74, and holds an Australian Financial
      Services licence (AFSL), number 478976.
    </P>
  ),
  [UserLocale.ES_ES]: (
    <P>
      GoCardless Ltd (10-20 Gwynne St, Cremorne, VIC 3121, Australia &ndash;
      número de registro de empresa 07495895) está registrada como empresa
      extranjera en Australia, ABN 17 606 261 74, y tiene una licencia de
      Servicios Financieros Australianos (AFSL), número 478976.
    </P>
  ),
  [UserLocale.FR_FR]: (
    <P>
      GoCardless Ltd (10-20 Gwynne St, Cremorne, VIC 3121, Australie &ndash;
      immatriculée sous le numéro 07495895) est enregistrée en tant que société
      étrangère en Australie, ABN 17 606 261 74, et détient un agrément
      australien de services financiers (AFSL) sous le numéro 478976.
    </P>
  ),
};

const GcLtdUkFooterText: Record<UserLocale, ReactElement> = {
  [UserLocale.DE_DE]: (
    <P>
      GoCardless Ltd (Sutton Yard, 65 Goswell Road, London, EC1V 7EN,
      Großbritannien &ndash; Unternehmensregisternummer 07495895) ist von der
      Financial Conduct Authority gemäß den Payment Services Regulations 2017
      mit der Registernummer 597190 für die Erbringung von
      Zahlungsdienstleistungen zugelassen.
    </P>
  ),
  [UserLocale.EN_GB]: (
    <P>
      GoCardless Ltd (Sutton Yard, 65 Goswell Road, London, EC1V 7EN, United
      Kingdom &ndash; company registration number 07495895) is authorised by the
      Financial Conduct Authority under the Payment Services Regulations 2017,
      registration number 597190, for the provision of payment services.
    </P>
  ),
  [UserLocale.EN_US]: (
    <P>
      GoCardless Ltd (Sutton Yard, 65 Goswell Road, London, EC1V 7EN, United
      Kingdom &ndash; company registration number 07495895) is authorised by the
      Financial Conduct Authority under the Payment Services Regulations 2017,
      registration number 597190, for the provision of payment services.
    </P>
  ),
  [UserLocale.ES_ES]: (
    <P>
      GoCardless Ltd (Sutton Yard, 65 Goswell Road, Londres, Reino Unido, EC1V
      7EN &ndash; número de registro de empresa 07495895) está autorizada por la
      Autoridad de Conducta Financiera del Reino Unido en virtud del Reglamento
      de Servicios de Pago de 2017, número de registro 597190, para la
      prestación de servicios de pago.
    </P>
  ),
  [UserLocale.FR_FR]: (
    <P>
      GoCardless Ltd (Sutton Yard, 65 Goswell Road, Londres, EC1V 7EN,
      Royaume-Uni &ndash; immatriculée sous le numéro 07495895) est autorisée
      par la Financial Conduct Authority en vertu du Règlement sur les services
      de paiement de 2017 (Payment Services Regulations), numéro
      d&apos;immatriculation 597190, pour la fourniture de services de paiement.
    </P>
  ),
};

export const GoCardlessFooterText: FC = () => {
  const creditor = usePrimaryCreditor();
  const [locale] = useI18n();

  if (!creditor) return null;

  const { gc_segregation_entity: gcSegregationEntity, geo } = creditor;

  if (gcSegregationEntity === "gc_sas") {
    return GcSasFooterText[locale];
  } else if (gcSegregationEntity === "gc_inc") {
    return GcIncFooterText[locale];
  } else {
    // assume segregation entity is gc_ltd
    if (geo && ["AU", "NZ"].includes(geo)) {
      return GcLtdAustraliaFooterText[locale];
    } else {
      return GcLtdUkFooterText[locale];
    }
  }
};
