import { FieldValues, useFormContext } from "react-hook-form";
import { Box, BoxProps, FormFieldStatus, Hint } from "@gocardless/flux-react";
import { FC } from "react";

interface FieldErrorProps extends BoxProps {
  name: keyof FieldValues;
}

export const FieldError: FC<FieldErrorProps> = ({ name, ...props }) => {
  const {
    formState: { errors },
  } = useFormContext();

  const message = errors[name]?.message?.toString();

  if (!message) return null;

  return (
    <Box {...props}>
      <Hint status={FormFieldStatus.Danger}>
        <>{message}</>
      </Hint>
    </Box>
  );
};
