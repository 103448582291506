import {
  ConnectBankAccountAisAuthorisationUrlRequestBody,
  ConnectBankAccountAisAuthorisationUrlResponseBody,
  useConnectBankAccountAisAuthorisationUrl,
} from "@gocardless/api/dashboard/connect-bank-account";
import {
  CreditorDetailUpdateRequestBody,
  CreditorDetailUpdateResponseBody,
  useCreditorDetailSelf,
  useCreditorDetailUpdate,
} from "@gocardless/api/dashboard/creditor-detail";
import { useInstitutionList } from "@gocardless/api/dashboard/institution";
import {
  CreditorType,
  InstitutionResource,
} from "@gocardless/api/dashboard/types";
import {
  UseMutationParams,
  DefaultQueryParams,
} from "@gocardless/api/utils/use-mutation";
import { useEffect } from "react";

import { useBankDetails } from "../bank-details/hooks/useBankDetails";
import { UseSetupPage } from "../routing/types";

import { Route, routerPush } from "src/common/routing";
import { usePrimaryCreditor } from "src/libraries/creditor/hooks";
import { TopPopularBanks } from "src/common/banks/topPopularBanks";
import { rawPaths } from "src/common/routing/routes";

export interface UseBankDetailsSelect extends UseSetupPage {
  institutions: InstitutionResource[];
  companyName: string;
  individualGivenName: string;
  individualFamilyName: string;
  individualTradingName: string;
  submitUpdateCreditorDetail: ({ name }: { name: string }) => void;
  submitCreateAuthorisationUrl: ({
    institutionId,
  }: {
    institutionId: string;
  }) => void;
  creditorType: CreditorType | null;
}

function sortInstitutions(
  institutions: InstitutionResource[],
  geo: string
): InstitutionResource[] {
  const topIds = TopPopularBanks[geo] || [];

  const institutionsCopy = institutions.slice();
  const sortedInstitutions: InstitutionResource[] = [];

  // in order, take each of the top 8 institutions from the input list and add it to the result.
  topIds.forEach((id) => {
    const index = institutionsCopy.findIndex(
      (institution) => institution.id === id
    );

    if (index >= 0) {
      sortedInstitutions.push(institutionsCopy[index] as InstitutionResource);

      // remove the matched institution from the input list so it isn't added twice
      institutionsCopy.splice(index, 1);
    }
  });

  // add the remaining institutions from the list after the sorted ones
  return [...sortedInstitutions, ...institutionsCopy];
}

export const useBankDetailsSelect = ({
  institutionFilter,
  creditorDetailUpdateParams,
  connectBankAccountAisAuthorisationUrlParams,
}: {
  institutionFilter?: string;
  creditorDetailUpdateParams?: UseMutationParams<
    CreditorDetailUpdateResponseBody,
    CreditorDetailUpdateRequestBody,
    DefaultQueryParams
  >;
  connectBankAccountAisAuthorisationUrlParams?: UseMutationParams<
    ConnectBankAccountAisAuthorisationUrlResponseBody,
    ConnectBankAccountAisAuthorisationUrlRequestBody,
    DefaultQueryParams
  >;
} = {}): UseBankDetailsSelect => {
  const { completed: bankDetailsCompleted, skip: bankDetailsSkip } =
    useBankDetails();

  const skip = !bankDetailsSkip;

  const tempCreditor = usePrimaryCreditor();
  const { data: creditorDetail, revalidate: revalidateCreditorDetail } =
    useCreditorDetailSelf(tempCreditor?.id || "", !skip && !!tempCreditor);

  const isUSCreditor = tempCreditor?.geo === "US";
  const shouldFilterIds = isUSCreditor && !institutionFilter;
  const currentPage = window.location.pathname;

  // add ids to US creditors to avoid retrieving 6k+ institutions
  const { data: institutions } = useInstitutionList(
    {
      country_code: tempCreditor?.geo || "GB",
      features: "ais",
      search_keyword: institutionFilter,
      ids: shouldFilterIds ? TopPopularBanks["US"]?.join(",") : [],
    },
    !skip && !!tempCreditor
  );
  const [updateCreditorDetail] = useCreditorDetailUpdate(
    tempCreditor?.id || "",
    creditorDetailUpdateParams
  );
  const [createConnectBankAccountAisAuthorisationUrl] =
    useConnectBankAccountAisAuthorisationUrl(
      connectBankAccountAisAuthorisationUrlParams
    );

  const submitUpdateCreditorDetail = ({ name }: { name: string }) => {
    const body = {
      creditor_type: tempCreditor?.creditor_type,
      detail: {
        name,
      },
    };

    updateCreditorDetail(body).then(revalidateCreditorDetail);
  };

  const submitCreateAuthorisationUrl = ({
    institutionId,
  }: {
    institutionId: string;
  }) => {
    createConnectBankAccountAisAuthorisationUrl({
      institution_id: institutionId,
    });
  };

  const copVariantEnabled = tempCreditor?.cop_enabled;

  useEffect(() => {
    if (copVariantEnabled && currentPage === rawPaths.BankDetailsSelect) {
      routerPush({ route: Route.BankDetails, queryParams: { manual: "true" } });
    }
  }, [currentPage, copVariantEnabled]);

  return {
    institutions: sortInstitutions(
      institutions?.institutions ?? [],
      tempCreditor?.geo ?? "GB"
    ),
    companyName: creditorDetail?.creditor_details?.detail?.name ?? "",
    individualGivenName:
      creditorDetail?.creditor_details?.detail?.person?.given_name ?? "",
    individualFamilyName:
      creditorDetail?.creditor_details?.detail?.person?.family_name ?? "",
    individualTradingName:
      creditorDetail?.creditor_details?.detail?.trading_name ?? "",
    submitUpdateCreditorDetail,
    submitCreateAuthorisationUrl,
    creditorType: tempCreditor?.creditor_type ?? null,
    loaded: !!institutions?.institutions,
    skip,
    completed: bankDetailsCompleted,

    route: Route.BankDetailsSelect,
  };
};
