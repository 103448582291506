import { get, hasIn } from "lodash";
import { Controller, Path, useFormContext } from "react-hook-form";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Field, Label, Hint, FormFieldStatus } from "@gocardless/flux-react";
import { I18n } from "@lingui/core";
import { TrusteeType } from "@gocardless/api/dashboard/types";
import { useEffect } from "react";

import { BusinessDirectorsFormType } from "../../business-directors/BusinessDirectorsForm";
import DatePickerInput from "../components/DatePickerInput";

import { CountryCodes } from "src/common/country";
import {
  Field as Config,
  FieldArrayProps,
} from "src/components/routes/Setup/common/config/types";
import {
  getInputErrorStatus,
  VALID_DATE_REGEX,
} from "src/utils/inputValidation";

type DateOfBirthFieldProps = {
  fieldPath: string;
  countryCode: CountryCodes;
} & FieldArrayProps;

enum DateFormats {
  DDMMYYYY = "DD/MM/YYYY",
  MMDDYYYY = "MM/DD/YYYY",
  YYYYMMDD = "YYYY/MM/DD",
}

const datei18nErrorMessage = (i18nn: I18n): Record<DateFormats, string> => ({
  [DateFormats.DDMMYYYY]: i18nn._(
    t({
      id: "setup.business-directors.enter-date-of-birth-dd-mm-yyyy",
      message: "Please enter a full date of birth (DD/MM/YYYY)",
    })
  ),
  [DateFormats.MMDDYYYY]: i18nn._(
    t({
      id: "setup.business-directors.enter-date-of-birth-mm-dd-yyyy",
      message: "Please enter a full date of birth (MM/DD/YYYY)",
    })
  ),
  [DateFormats.YYYYMMDD]: i18nn._(
    t({
      id: "setup.business-directors.enter-date-of-birth-yyyy-mm-dd",
      message: "Please enter a full date of birth (YYYY/MM/DD)",
    })
  ),
});

const DateOfBirthPickerField: React.FC<DateOfBirthFieldProps> = ({
  defaultValue,
  fieldPath,
  countryCode,
}) => {
  const {
    control,
    unregister,
    formState: { errors },
  } = useFormContext<BusinessDirectorsFormType>();

  const { i18n } = useLingui();

  useEffect(
    () => () => unregister(fieldPath as Path<BusinessDirectorsFormType>),
    [fieldPath, unregister]
  );

  const dateIntl = [CountryCodes.US].includes(countryCode)
    ? DateFormats.MMDDYYYY
    : DateFormats.DDMMYYYY;

  return (
    <Field>
      <Label htmlFor={fieldPath}>
        <Trans id="Date of birth">Date of birth</Trans>
      </Label>
      <Controller<BusinessDirectorsFormType>
        name={fieldPath as Path<BusinessDirectorsFormType>}
        defaultValue={defaultValue}
        rules={{
          required: datei18nErrorMessage(i18n)[dateIntl],
          pattern: {
            value: VALID_DATE_REGEX,
            message: datei18nErrorMessage(i18n)[dateIntl],
          },
        }}
        control={control}
        render={({ field }) => (
          <DatePickerInput
            {...field}
            onChange={field.onChange}
            status={getInputErrorStatus(hasIn(errors, fieldPath))}
            initialDate={defaultValue}
            countryCode={countryCode}
            className="fs-exclude"
          />
        )}
      />
      {hasIn(errors, fieldPath) && (
        <Hint status={FormFieldStatus.Danger}>
          {get(errors, `${fieldPath}.message`)}
        </Hint>
      )}
    </Field>
  );
};

const DirectorDateOfBirthField: React.FC<DateOfBirthFieldProps> = (props) => (
  <DateOfBirthPickerField
    {...props}
    fieldPath={`directors[${props.index}].date_of_birth`}
  />
);

const OwnerDateOfBirthField: React.FC<DateOfBirthFieldProps> = (props) => (
  <DateOfBirthPickerField
    {...props}
    fieldPath={`shareholders[${props.index}].date_of_birth`}
  />
);

const ControlDateOfBirthField: React.FC<DateOfBirthFieldProps> = (props) => (
  <DateOfBirthPickerField
    {...props}
    fieldPath={`control_prongs[${props.index}].date_of_birth`}
  />
);

const PersonDateOfBirthField: React.FC<DateOfBirthFieldProps> = (props) => (
  <DateOfBirthPickerField {...props} fieldPath="person.date_of_birth" />
);

const TrusteeDateOfBirthField: React.FC<DateOfBirthFieldProps> = (props) => (
  <DateOfBirthPickerField
    {...props}
    fieldPath={`trustees[${props.index}].date_of_birth`}
  />
);

const PartnerDateOfBirthField: React.FC<DateOfBirthFieldProps> = (props) => (
  <DateOfBirthPickerField
    {...props}
    fieldPath={`partners[${props.index}].date_of_birth`}
  />
);

const UBODateOfBirthField: React.FC<DateOfBirthFieldProps> = (props) => (
  <DateOfBirthPickerField
    {...props}
    fieldPath={`ultimate_beneficial_owners[${props.index}].date_of_birth`}
  />
);

export const directorConfig: Config = {
  name: "date_of_birth",
  displayName: "Date of birth",
  required: true,
  component: DirectorDateOfBirthField,
};

export const ownerConfig: Config = {
  name: "date_of_birth",
  displayName: "Date of birth",
  component: OwnerDateOfBirthField,
};

export const controlConfig: Config = {
  name: "date_of_birth",
  displayName: "Date of birth",
  required: true,
  component: ControlDateOfBirthField,
};

export const personConfig: Config = {
  name: "date_of_birth",
  displayName: "Date of birth",
  component: PersonDateOfBirthField,
};

export const trusteeConfig: Config = {
  name: "date_of_birth",
  displayName: "Date of birth",
  required: ({ type }) => !type || type === TrusteeType.Person,
  component: TrusteeDateOfBirthField,
};

export const partnerConfig: Config = {
  name: "date_of_birth",
  displayName: "Date of birth",
  required: true,
  component: PartnerDateOfBirthField,
};

export const uboConfig: Config = {
  name: "date_of_birth",
  displayName: "Date of birth",
  required: true,
  component: UBODateOfBirthField,
};

export default DateOfBirthPickerField;
