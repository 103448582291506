import {
  CharityType,
  CreditorType,
  TrusteeElement,
} from "@gocardless/api/dashboard/types";
import { CreditorDetailUpdateRequestBody } from "@gocardless/api/dashboard/creditor-detail";
import { HTTPError } from "@gocardless/api/utils/api";

import { UseSetupPage } from "../routing/types";
import { useSetupData } from "../common/hooks/useSetupData";
import { useComplete } from "../common/validators/useComplete";
import {
  getTrusteeDetails,
  getTrustees,
  TrusteeDetails,
} from "../common/builders/trustees";

import { SetupPages } from "src/components/routes/Setup/common/config/types";
import { Route } from "src/common/routing";

export interface UseTrusteeDetails extends UseSetupPage {
  trusteeDetails: TrusteeDetails;
  trustees: TrusteeElement[];
  submitTrusteeDetails: (newTrustees: TrusteeElement[]) => void;
  geo: string;
  gc_segregation_entity: string;
}

export function useTrusteeDetails({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: () => void;
  onError?: (error: HTTPError) => void;
} = {}): UseTrusteeDetails {
  const { creditor, creditorDetails, updateCreditorDetail } = useSetupData(
    onSuccess,
    onError
  );
  const { isListComplete } = useComplete(SetupPages.TRUSTEE_DETAILS);
  const trusteeDetails = getTrusteeDetails(creditorDetails);
  const trustees = getTrustees(trusteeDetails);
  const { creditor_type, charity_type } = trusteeDetails;

  const completed = isListComplete(
    creditor_type || CreditorType.Charity,
    trusteeDetails.trustees
  );

  const submitTrusteeDetails = (data: TrusteeElement[]) => {
    const { account_holder_is_trustee, signatories } = trusteeDetails;
    const signatory = signatories[0];
    const put: CreditorDetailUpdateRequestBody = {
      creditor_type: creditor?.creditor_type,
      detail: {
        trustees:
          account_holder_is_trustee && signatory
            ? [
                ...data,
                {
                  given_name: signatory.given_name,
                  family_name: signatory.family_name,
                  date_of_birth: signatory.date_of_birth,
                  place_of_birth: signatory.place_of_birth,
                  country_code: signatory.country_code,
                },
              ]
            : data,
      },
    };
    updateCreditorDetail(put);
  };

  const skip =
    (creditor_type !== CreditorType.Charity ||
      charity_type !== CharityType.RegisteredCharity) &&
    creditor_type !== CreditorType.Trust;

  return {
    loaded: !!creditor && !!creditorDetails,
    skip,
    completed,
    route: Route.TrusteeDetails,
    trusteeDetails,
    trustees,
    submitTrusteeDetails,
    geo: creditor?.geo ?? "",
    gc_segregation_entity: creditor?.gc_segregation_entity ?? "",
  };
}
