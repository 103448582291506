import { ReactElement, createContext, useState } from "react";
import Head from "next/head";
import { AnimatePresence } from "framer-motion";
import {
  Box,
  ColorPreset,
  Container,
  Space,
  XYGrid,
} from "@gocardless/flux-react";
import { useLingui } from "@lingui/react";

import AuthWrapper from "src/components/authentication";
import { Route, routerReplace } from "src/common/routing";
import SetupPages from "src/components/routes/Setup/common/constants/SetupPages";
import Header from "src/components/routes/Setup/common/components/Header";
import Footer from "src/components/routes/Setup/common/components/Footer";
import ThreatMetrixProfiler from "src/technical-integrations/threat-metrix";
import SetupPageLabels from "src/components/routes/Setup/common/constants/SetupPageLabels";
import { useRouting } from "src/components/routes/Setup/routing/useRouting";
import SidebarComponent from "src/components/routes/Setup/common/components/Sidebar";

export interface SetupPageLayoutProps {
  setupPage: SetupPages;
  children: React.ReactNode;
}

export const getLayout = (setupPage: SetupPages) => (page: ReactElement) => (
  <AuthWrapper>
    <SetupPageContent setupPage={setupPage}>{page}</SetupPageContent>
  </AuthWrapper>
);

export const NavRouteContext = createContext<{
  backRoute: Route;
  nextRoute: Route;
  skipDefaultRoute: boolean;
  setSkipDefaultRoute: (skip: boolean) => void;
}>({
  backRoute: Route.PackageSelection,
  nextRoute: Route.AccountStatus,
  skipDefaultRoute: false,
  setSkipDefaultRoute: () => {},
});

const SetupPageContent = ({ children, setupPage }: SetupPageLayoutProps) => {
  const [sideBarVisible, setSideBarVisible] = useState<boolean>(false);
  const [skipDefaultRoute, setSkipDefaultRoute] = useState<boolean>(false);
  const { i18n } = useLingui();
  const {
    loaded,
    defaultRoute,
    backRoute = Route.PackageSelection,
    nextRoute = Route.AccountStatus,
    setupSteps = [],
  } = useRouting(setupPage, skipDefaultRoute);
  if (setupPage === SetupPages.Index && loaded && defaultRoute) {
    routerReplace({ route: defaultRoute });
  }

  return (
    <>
      <Head>
        <title>{SetupPageLabels(i18n, setupPage)} • GoCardless</title>
      </Head>
      <ThreatMetrixProfiler />
      <XYGrid templateColumns="max-content auto">
        <SidebarComponent
          sideBarVisible={sideBarVisible}
          setupPage={setupPage}
          setupSteps={setupSteps}
          closeSidebar={() => setSideBarVisible(false)}
        />
        <Box bg={ColorPreset.BackgroundLight_02}>
          <Header
            setupPage={setupPage}
            setupSteps={setupSteps}
            openSideBar={() => setSideBarVisible(true)}
          />
          <Container contentGutters={[2, null, 0]}>
            <AnimatePresence
              exitBeforeEnter
              initial={false}
              onExitComplete={() => window.scrollTo(0, 0)}
            >
              {loaded && (
                <NavRouteContext.Provider
                  value={{
                    backRoute,
                    nextRoute,
                    skipDefaultRoute,
                    setSkipDefaultRoute,
                  }}
                >
                  {children}
                </NavRouteContext.Provider>
              )}
              {!loaded && <Space v={48} />}
            </AnimatePresence>
            <Footer />
          </Container>
        </Box>
      </XYGrid>
    </>
  );
};
