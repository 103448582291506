import {
  Banner,
  BannerStatus,
  BannerVariant,
  Box,
  FontWeight,
  Interpose,
  Space,
  Text,
  BannerLeftAccessoryType,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { TrustAddressInput } from "../common/components/Address/AddressInput";

import { BusinessDetailsConfig } from "./types";

import {
  Field as FieldType,
  FieldAttributes,
} from "src/components/routes/Setup/common/config/types";
import { renderFields } from "src/components/routes/Setup/common/config/utils";

interface TrustDetailsFormProps {
  fields: FieldType[];
  values: BusinessDetailsConfig;
}

const TrustDetailsForm = ({ values, fields }: TrustDetailsFormProps) => {
  const fieldAttributes: FieldAttributes = {
    geo: {},
    creditor_type: {},
    partnership_type: {},
    legal_name: {
      props: {
        defaultValue: values.legal_name,
      },
    },
    trading_name: {},
    trust_number: {},
    registered_address: {
      component: TrustAddressInput,
      props: {
        address: values,
        countryCode: values.geo,
      },
    },
  };

  return (
    <Box>
      <Interpose node={<Space v={2} />}>
        <Banner
          variant={BannerVariant.Light}
          leftAccessory={{
            type: BannerLeftAccessoryType.Status,
            status: BannerStatus.Info,
          }}
        >
          <Trans id="setup.business-details.trust-banner">
            We recommend having a copy of your{" "}
            <Text weight={FontWeight.Bold}>trust deed</Text> to hand as
            you&apos;ll be required to use these details and upload a copy later
            in the setup.
          </Trans>
        </Banner>
        {renderFields(fields, fieldAttributes)}
      </Interpose>
    </Box>
  );
};

export default TrustDetailsForm;
