import { Trans } from "@lingui/macro";

import * as Fields from "../fields";

export const BusinessDetails = [
  [Fields.Country, Fields.CreditorType],
  {
    name: "companyDetails",
    displayName: "Company Details",
    component: [
      Fields.LegalName,
      Fields.CompanyTradingName,
      Fields.CompanyNumber,
      {
        name: "registered_address",
        displayName: (
          <Trans id="setup.business-details.registered-address">
            Registered address
          </Trans>
        ),
        component: [
          Fields.CompanyAddressLineOne,
          Fields.CompanyAddressLineTwo,
          Fields.CompanyAddressLineThree,
          Fields.CompanyCity,
          Fields.CompanyPostalCode,
        ],
      },
    ],
  },
  Fields.VATNumber,
];

export const BusinessCategory = [
  {
    name: "businessCategory",
    displayName: "Business Category",
    component: [
      Fields.CategoryIndustry,
      Fields.CategoryCode,
      Fields.CategoryDescription,
      Fields.OnlinePresence,
    ],
  },
];

export const BusinessDirectors = [
  {
    name: "directorDetails",
    displayName: "Director Details",
    component: [
      Fields.DirectorGivenName,
      Fields.DirectorFamilyName,
      Fields.DirectorDateOfBirth,
    ],
  },
  Fields.DirectorSelect,
  Fields.DirectorCountryCode,
  {
    name: "directorAddress",
    displayName: "Director Address",
    component: [
      Fields.DirectorFlatNumber,
      Fields.DirectorBuildingNumber,
      Fields.DirectorBuildingName,
      Fields.DirectorStreet,
      Fields.DirectorCity,
      Fields.DirectorRegion,
      Fields.DirectorPostalCode,
    ],
  },
];

export const BusinessOwners = [
  Fields.OwnerAvailable,
  {
    name: "ownerMember",
    displayName: "Business Owner",
    component: [
      {
        name: "ownerDetails",
        displayName: "Owner Details",
        component: [
          Fields.OwnerGivenName,
          Fields.OwnerFamilyName,
          Fields.OwnerDateOfBirth,
          Fields.OwnerPlaceOfBirth,
        ],
      },
    ],
  },
];
