import { useEffect, useState } from "react";
import { get, omit } from "lodash";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

import AddressSearch, { AddressConfig } from "./AddressSearch";
import AddressManual from "./AddressManual";
import AddressCard from "./AddressCard";

import {
  retrieveAddress,
  RetrieveAddressResult,
  retrievePersonAddress,
  RetrievePersonAddressResult,
} from "src/queries/addressSearch";
import { Field } from "src/components/routes/Setup/common/config/types";

interface AddressInputProps {
  fields: Field[];
  address?: AddressConfig;
  retrieveAddressMethod: (
    id: string
  ) => Promise<RetrieveAddressResult | RetrievePersonAddressResult>;
  countryCode: string;
  index: number;
  requiredFieldNames: string[];
  addressFieldsToDisplayOnCard: string[];
  fieldPath: string;
  fieldMessage: string;
  description: string;
  errors: Errors;
}

interface Errors {
  control_prongs?: Array<object>;
}

const hasMissingRequiredFieldsFromAddress = (
  requiredFieldNames: string[],
  fields: AddressConfig | undefined
): boolean =>
  requiredFieldNames
    .map((name) => !get(fields, name))
    .reduce((bool, isMissing) => bool || isMissing);

const hasAddressErrors = (fields: Field[], errors: Errors): boolean => {
  if (fields && errors && errors.control_prongs) {
    const fieldNames = fields.map((field) => field.name);
    const addressErrors = errors.control_prongs
      .map((error) => Object.keys(error))
      .flat();
    return fieldNames.some((field) => addressErrors.includes(field));
  }
  return false;
};

const AddressInput: React.FC<AddressInputProps> = ({
  fields,
  address,
  countryCode,
  index,
  retrieveAddressMethod,
  requiredFieldNames,
  addressFieldsToDisplayOnCard,
  fieldPath,
  fieldMessage,
  description,
  errors,
}) => {
  // setFinalAddress to pass the selected address from search to the manual form
  const [finalAddress, setFinalAddress] = useState<AddressConfig | undefined>(
    address
  );

  const comparableFinalAddress = omit(finalAddress, ["country_code"]);

  const hasEmptyAddress = fields
    .map((field) => get(comparableFinalAddress, field.name))
    .every((value) => !value);

  const [searchAddress, setSearchAddress] = useState<boolean>(hasEmptyAddress);
  const [manualAddress, setManualAddress] = useState<boolean>(
    hasMissingRequiredFieldsFromAddress(requiredFieldNames, finalAddress) &&
      !hasEmptyAddress
  );
  if (
    !searchAddress &&
    !manualAddress &&
    hasMissingRequiredFieldsFromAddress(requiredFieldNames, finalAddress)
  ) {
    if (hasEmptyAddress) {
      setSearchAddress(true);
    } else {
      setManualAddress(true);
    }
  }

  if (!searchAddress && !manualAddress && hasAddressErrors(fields, errors)) {
    setManualAddress(true);
  }

  const selectSearchedAddress = (fullAddress: AddressConfig) => {
    setFinalAddress(fullAddress);
    if (
      hasMissingRequiredFieldsFromAddress(requiredFieldNames, fullAddress) ||
      hasAddressErrors(fields, errors)
    ) {
      setManualAddress(true);
    }
    setSearchAddress(false);
  };

  const onChooseSearchOption = () => {
    setFinalAddress({});
    setSearchAddress(true);
    setManualAddress(false);
  };

  useEffect(() => {
    if (
      !!finalAddress?.country_code &&
      countryCode !== finalAddress?.country_code
    ) {
      onChooseSearchOption();
    }
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  const addressManual = (hidden: boolean) => (
    <AddressManual
      fields={fields}
      index={index}
      defaultValue={finalAddress || {}}
      countryCode={countryCode}
      onChooseSearchOption={onChooseSearchOption}
      hidden={hidden}
    />
  );

  if (searchAddress) {
    return (
      <>
        {addressManual(true)}
        <AddressSearch
          country_code={countryCode}
          onSelectAddress={selectSearchedAddress}
          onChooseManualOption={() => {
            setSearchAddress(false);
            setManualAddress(true);
          }}
          retrieveAddress={retrieveAddressMethod}
          fieldPath={fieldPath}
          fieldMessage={fieldMessage}
          description={description}
          index={index}
        />
      </>
    );
  }
  if (manualAddress) {
    return addressManual(false);
  }

  return (
    <>
      {addressManual(true)}
      <AddressCard
        address={finalAddress}
        onChooseManualOption={() => {
          setManualAddress(true);
        }}
        onChooseSearchOption={() => setSearchAddress(true)}
        addressFieldsToDisplayOnCard={addressFieldsToDisplayOnCard}
        fieldPath={fieldPath}
        fieldMessage={fieldMessage}
        description={description}
        countryCode={countryCode}
      />
    </>
  );
};

export const CharityAddressInput: React.FC<AddressInputProps> = (props) => (
  <AddressInput
    {...props}
    retrieveAddressMethod={retrieveAddress}
    requiredFieldNames={["address_line1", "city", "postal_code"]}
    addressFieldsToDisplayOnCard={[
      "address_line1",
      "address_line2",
      "address_line3",
    ]}
    fieldPath="setup.business-details.charity-address"
    fieldMessage={i18n._(
      t({
        id: "setup.business-details.charity-address",
        message: "Charity address",
      })
    )}
  />
);

export const PersonAddressInput: React.FC<AddressInputProps> = (props) => (
  <AddressInput
    {...props}
    retrieveAddressMethod={retrievePersonAddress}
    requiredFieldNames={["street", "city", "postal_code"]}
    addressFieldsToDisplayOnCard={[
      "flat_number",
      "building_name",
      "building_number",
      "street",
    ]}
    fieldPath="setup.home-address"
    fieldMessage={i18n._(
      t({
        id: "Home address",
        message: "Home address",
      })
    )}
  />
);

export const ControlProngAddressInput: React.FC<AddressInputProps> = (
  props
) => (
  <AddressInput
    {...props}
    retrieveAddressMethod={retrievePersonAddress}
    requiredFieldNames={["street", "city", "postal_code"]}
    addressFieldsToDisplayOnCard={[
      "flat_number",
      "building_name",
      "building_number",
      "street",
    ]}
    fieldPath="setup.control-prong-address"
    fieldMessage={i18n._(
      t({
        id: "Home address",
        message: "Home address",
      })
    )}
  />
);

export const AboutYouPersonAddressInput: React.FC<AddressInputProps> = (
  props
) => (
  <AddressInput
    {...props}
    retrieveAddressMethod={retrievePersonAddress}
    requiredFieldNames={["street", "city", "postal_code"]}
    addressFieldsToDisplayOnCard={[
      "flat_number",
      "building_name",
      "building_number",
      "street",
    ]}
    fieldPath="setup.home-address"
    fieldMessage={i18n._(
      t({
        id: "Home address",
        message: "Home address",
      })
    )}
    description={i18n._(
      t({
        id: "setup.about-you.home-address.description",
        message:
          "This must be your home address not business address (if they’re different)",
      })
    )}
  />
);

export const TrusteeDetailsAddressInput: React.FC<AddressInputProps> = (
  props
) => (
  <AddressInput
    {...props}
    retrieveAddressMethod={retrievePersonAddress}
    requiredFieldNames={["street", "city", "postal_code"]}
    addressFieldsToDisplayOnCard={[
      "flat_number",
      "building_name",
      "building_number",
      "street",
    ]}
    fieldPath="setup.home-address"
    fieldMessage={i18n._(
      t({
        id: "Home address",
        message: "Home address",
      })
    )}
    description={i18n._(
      t({
        id: "setup.about-you.home-address.trustee.description",
        message:
          "We just need the home address of the first trustee you add for regulatory reasons",
      })
    )}
  />
);

export const PartnershipAddressInput: React.FC<AddressInputProps> = (props) => (
  <AddressInput
    {...props}
    retrieveAddressMethod={retrieveAddress}
    requiredFieldNames={["address_line1", "city", "postal_code"]}
    addressFieldsToDisplayOnCard={[
      "address_line1",
      "address_line2",
      "address_line3",
    ]}
    fieldPath="Legal address"
    fieldMessage="Legal address"
  />
);

export const TrustAddressInput: React.FC<AddressInputProps> = (props) => (
  <AddressInput
    {...props}
    retrieveAddressMethod={retrieveAddress}
    requiredFieldNames={["address_line1", "city", "postal_code"]}
    addressFieldsToDisplayOnCard={[
      "address_line1",
      "address_line2",
      "address_line3",
    ]}
    fieldPath="setup.trust-address"
    fieldMessage={i18n._(
      t({
        id: "setup.trust-address",
        message: "Trust address",
      })
    )}
  />
);
