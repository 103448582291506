import { get } from "lodash";
import {
  Box,
  FieldSet,
  FontWeight,
  FormFieldStatus,
  Glyph,
  Hint,
  HoverEffect,
  Icon,
  JustifyContent,
  Legend,
  PlainLink,
  Radio,
  Space,
  Text,
  ToggleVariant,
  TypePreset,
  XYGrid,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { Trans } from "@lingui/macro";

import { Field as Config } from "../config/types";

import { BusinessOwnersConfig } from "src/components/routes/Setup/business-owners/useBusinessOwners";

const OwnerAvailableField: React.FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<BusinessOwnersConfig>();

  return (
    <Box css={{ "input + div": { "--toggle-bg": "transparent" } }}>
      <FieldSet>
        <Legend>
          <Trans id="setup.business-owner.owner-question">
            Are there any people who own or control 25% or more of the business?
          </Trans>
        </Legend>
        <XYGrid
          templateColumns={["1fr", null, "1fr 1fr"]}
          columnGap={[null, null, 0.75]}
          rowGap={0.75}
          justifyContent={JustifyContent.Center}
        >
          <Radio
            {...register("ownerAvailable", {
              required: "Please choose Yes or No",
            })}
            variant={ToggleVariant.Emphasized}
            id="yes"
            value="yes"
          >
            <Trans id="setup.business-owner.owner-available-yes">Yes</Trans>
          </Radio>
          <Radio
            {...register("ownerAvailable", {
              required: "Please choose Yes or No",
            })}
            variant={ToggleVariant.Emphasized}
            id="no"
            value="no"
          >
            <Trans id="setup.business-owner.owner-available-no">No</Trans>
          </Radio>
        </XYGrid>
        {errors.ownerAvailable && (
          <Hint status={FormFieldStatus.Danger}>
            {get(errors, "ownerAvailable.message")}
          </Hint>
        )}
      </FieldSet>
      <Space v={2} />
      <PlainLink
        href="https://support.gocardless.com/hc/en-gb/articles/360000561645-Ultimate-Beneficial-Owners"
        target="_blank"
        effect={HoverEffect.TextDecoration}
      >
        <Text preset={TypePreset.Body_01}>
          <Text weight={FontWeight.Bold}>
            <Trans id="setup.business-owners.read-faq">
              Not sure? Read our FAQs about business owners
            </Trans>
          </Text>
          <Space layout="inline" h={0.5} />
          <Icon name={Glyph.Export} size="12px" />
        </Text>
      </PlainLink>
    </Box>
  );
};

export const config: Config = {
  name: "ownerAvailable",
  displayName: "Owner Available",
  component: OwnerAvailableField,
};

export default OwnerAvailableField;
