import { useEffect } from "react";
import { get, hasIn } from "lodash";
import { useFormContext } from "react-hook-form";
import {
  Field,
  Label,
  Input,
  Hint,
  FormFieldStatus,
} from "@gocardless/flux-react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { presenceCheck } from "./helpers";

import {
  Field as Config,
  FieldArrayProps,
} from "src/components/routes/Setup/common/config/types";

type PassportNumberFieldProps = {
  fieldPath: string;
  personName?: string;
} & FieldArrayProps;

const PassportNumberField: React.FC<PassportNumberFieldProps> = ({
  defaultValue,
  fieldPath,
  personName,
}) => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
    unregister,
  } = useFormContext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => unregister(fieldPath), []);
  return (
    <Field>
      <Label htmlFor={fieldPath}>
        {personName ? (
          <Trans id="passport-number-for">
            Passport number for {personName}
          </Trans>
        ) : (
          <Trans id="passport-number">Passport number</Trans>
        )}
      </Label>
      <Input
        {...register(
          fieldPath,
          presenceCheck(
            i18n._(
              t({
                id: `setup.business-owners.passport-error`,
                message: "Please enter a valid passport number",
              })
            )
          )
        )}
        id={fieldPath}
        defaultValue={defaultValue}
        className="fs-exclude"
      />
      {hasIn(errors, fieldPath) && (
        <Hint status={FormFieldStatus.Danger}>
          {get(errors, `${fieldPath}`)?.message as string}
        </Hint>
      )}
    </Field>
  );
};

const DirectorPassportNumberField: React.FC<FieldArrayProps> = (props) => (
  <PassportNumberField
    {...props}
    fieldPath={`directors[${props.index}].passport_number`}
  />
);

const ControlPassportNumberField: React.FC<FieldArrayProps> = (props) => (
  <PassportNumberField
    {...props}
    fieldPath={`control_prongs[${props.index}].passport_number`}
  />
);

const OwnerPassportNumberField: React.FC<FieldArrayProps> = (props) => (
  <PassportNumberField
    {...props}
    fieldPath={`shareholders[${props.index}].passport_number`}
  />
);

const PersonPassportNumberField: React.FC<FieldArrayProps> = (props) => (
  <PassportNumberField {...props} fieldPath="person.passport_number" />
);

export const directorConfig: Config = {
  name: "passport_number",
  displayName: "Passport Number",
  component: DirectorPassportNumberField,
};

export const controlConfig: Config = {
  name: "passport_number",
  displayName: "Passport Number",
  component: ControlPassportNumberField,
};

export const ownerConfig: Config = {
  name: "passport_number",
  displayName: "Passport Number",
  component: OwnerPassportNumberField,
};

export const personConfig: Config = {
  name: "passport_number",
  displayName: "Passport Number",
  component: PersonPassportNumberField,
};

export default PassportNumberField;
