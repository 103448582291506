import { useCallback } from "react";

import {
  getItem,
  removeItem,
  setItem,
} from "src/common/local-storage/local-storage";

export const usePackageSelectionSkipped = (organisationId?: string) => {
  const key = `gc.${organisationId}.package-selection-skipped`;

  const packageSelectionSkipped = Boolean(getItem(key));

  const setPackageSelectionSkipped = useCallback(() => {
    setItem(key, String(true));
  }, [key]);

  const unsetPackageSelectionSkipped = useCallback(() => {
    removeItem(key);
  }, [key]);

  return {
    packageSelectionSkipped,
    setPackageSelectionSkipped,
    unsetPackageSelectionSkipped,
  };
};
