import { t } from "@lingui/macro";
import { I18n } from "@lingui/core";

import SetupPages from "./SetupPages";

const stepLabels = (i18n: I18n, page: SetupPages): string => {
  const config = {
    [SetupPages.Index]: i18n._(
      t({ id: "setup.steps.select-package", message: "Select package" })
    ),
    [SetupPages.PackageSelection]: i18n._(
      t({ id: "setup.steps.select-package", message: "Select package" })
    ),
    [SetupPages.PackageSelectionSecondaryStep]: i18n._(
      t({ id: "setup.steps.select-package", message: "Select package" })
    ),
    [SetupPages.BusinessDetails]: i18n._(
      t({ id: "setup.steps.business-details", message: "Business details" })
    ),
    [SetupPages.MoreBusinessDetails]: i18n._(
      t({ id: "setup.steps.more-business-details", message: "Business size" })
    ),
    [SetupPages.AboutYou]: i18n._(
      t({ id: "setup.steps.about-you", message: "About You" })
    ),
    [SetupPages.ContactDetails]: i18n._(
      t({ id: "setup.steps.contact-details", message: "Contact details" })
    ),
    [SetupPages.BillingDetails]: i18n._(
      t({ id: "setup.steps.billing-details", message: "Billing details" })
    ),
    [SetupPages.BusinessCategory]: i18n._(
      t({ id: "setup.steps.business-category", message: "Business category" })
    ),
    [SetupPages.PaymentVolumes]: i18n._(
      t({ id: "setup.steps.payment-volumes", message: "Payment volumes" })
    ),
    [SetupPages.PersonActingOnBehalfOf]: i18n._(
      t({ id: "setup.steps.about-you", message: "About You" })
    ),
    [SetupPages.TrusteeDetails]: i18n._(
      t({ id: "setup.steps.trustee-details", message: "Trustee details" })
    ),
    [SetupPages.TrustOwners]: i18n._(
      t({
        id: "setup.steps.trust-owners",
        message: "Ultimate beneficial owners",
      })
    ),
    [SetupPages.BusinessDirectors]: i18n._(
      t({ id: "setup.steps.business-directors", message: "Business directors" })
    ),
    [SetupPages.BusinessOwners]: i18n._(
      t({ id: "setup.steps.business-owners", message: "Business owners" })
    ),
    [SetupPages.AchUpliftDetails]: i18n._(
      t({
        id: "setup.steps.ach-uplift-details",
        message: "Additional information",
      })
    ),
    [SetupPages.BankDetails]: i18n._(
      t({ id: "setup.steps.add-bank-account", message: "Add bank account" })
    ),
    [SetupPages.BankDetailsSelect]: i18n._(
      t({ id: "setup.steps.add-bank-account", message: "Add bank account" })
    ),
    [SetupPages.VerifyBank]: i18n._(
      t({
        id: "setup.steps.verify-bank-account",
        message: "Verify bank account",
      })
    ),
    [SetupPages.VerifyBankDocument]: i18n._(
      t({
        id: "setup.steps.verify-bank-document",
        message: "Verify bank document",
      })
    ),
    [SetupPages.AccountStatus]: i18n._(
      t({
        id: "setup.steps.verification-status",
        message: "Verification status",
      })
    ),
    [SetupPages.BankStatementName]: i18n._(
      t({
        id: "setup.steps.bank-statement-name",
        message: "Bank statement name",
      })
    ),
  };

  return config[page];
};

export default stepLabels;
