import { get } from "lodash";
import {
  CreditorDetailUpdateResponseBody,
  CreditorType,
} from "@gocardless/api/dashboard/types";
import { HTTPError } from "@gocardless/api/utils/api";
import {
  useCreditorDetailSelf,
  useCreditorDetailUpdate,
} from "@gocardless/api/dashboard/creditor-detail";

import { UseSetupPage } from "../routing/types";
import { AddressConfig } from "../common/components/Address/AddressSearch";
import { useComplete } from "../common/validators/useComplete";
import { SetupPages } from "../common/config/types";
import {
  ownersBuilder,
  formatOwnersBuilder,
  uboSelfDeclaredBuilder,
} from "../common/builders/owners";

import { usePaobVerification } from "src/components/routes/Setup/common/hooks/usePaobVerification";
import { usePrimaryCreditor } from "src/libraries/creditor/hooks";
import { CountryCodes } from "src/common/country";
import { Route } from "src/common/routing";

export interface UseBusinessOwners extends UseSetupPage {
  businessOwners: BusinessOwnersConfig;
  countryCode: string;
  companyNumber: string;
  registeredCompanySearchId: string;
  achUpliftInitiated?: boolean;
  submitOwners: (
    data: BusinessOwnersConfig
  ) => Promise<CreditorDetailUpdateResponseBody | undefined>;
}

export type ShareHolderType = {
  given_name?: string;
  family_name?: string;
  date_of_birth?: string;
  place_of_birth?: string;
  social_security_number?: string;
  passport_number?: string;
  passport_country?: string;
} & AddressConfig;

export type ultimateBeneficialOwnerType = {
  given_name?: string;
  family_name?: string;
  date_of_birth?: string;
  place_of_birth?: string;
  provider_name?: string;
} & AddressConfig;

export type ControlProngType = {
  given_name?: string;
  family_name?: string;
  date_of_birth?: string;
  social_security_number?: string;
  passport_number?: string;
  passport_country?: string;
} & AddressConfig;

export interface BusinessOwnersConfig {
  shareholders?: ShareHolderType[];
  control_prongs?: ControlProngType[];
  ultimateBeneficialOwners?: ultimateBeneficialOwnerType[];
  ownerAvailable?: string;
  termsAndConditions?: boolean;
}

const isSupported = (creditorType: CreditorType, geo: string) => {
  if (creditorType === CreditorType.Company) {
    return true;
  }
  // supported for Australian Partnership merchants
  if (
    creditorType === CreditorType.Partnership &&
    (geo === CountryCodes.AU || geo === CountryCodes.CA)
  ) {
    return true;
  }
  return false;
};

export function useBusinessOwners({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: () => void;
  onError?: (error: HTTPError) => void;
} = {}): UseBusinessOwners {
  const creditor = usePrimaryCreditor();
  const { data: creditorDetailResponse, revalidate: refreshCreditorDetails } =
    useCreditorDetailSelf(creditor?.id || null);
  const creditorDetails = creditorDetailResponse?.creditor_details;
  const [updateCreditorDetail] = useCreditorDetailUpdate(creditor?.id || "", {
    onSuccess: () => {
      refreshCreditorDetails().then(() => onSuccess());
    },
    onError,
  });
  const { isListComplete } = useComplete(SetupPages.BUSINESS_OWNERS);
  const { gcSasVerificationEnabled } = usePaobVerification();

  const creditorType = get(creditorDetails, "creditor_type");
  const businessOwners = ownersBuilder(creditorDetails);
  const uboSelfDeclared =
    creditorDetails && uboSelfDeclaredBuilder(creditorDetails);
  const countryCode = get(
    creditorDetails,
    "detail.country_code",
    ""
  ) as CountryCodes;
  const companyNumber = get(creditorDetails, "detail.company_number", "");
  const companyType = get(creditorDetails, "detail.company_type");
  const registeredCompanySearchId = get(
    creditorDetails,
    "detail.registered_business_search_id",
    ""
  );

  const isGcSasCompany =
    gcSasVerificationEnabled && creditorType === CreditorType.Company;
  const isUSLimitedCompanyMerchant =
    countryCode === CountryCodes.US && creditorType === CreditorType.Company;
  const achUpliftInitiated = !!creditor?.ach_uplift_initiated;

  const submitOwners = async (owners: BusinessOwnersConfig) =>
    updateCreditorDetail({
      creditor_type: creditorDetails?.creditor_type,
      detail: {
        ...formatOwnersBuilder(owners, countryCode, creditorType),
        ultimate_beneficial_owners_self_declared: true,
        ...(isUSLimitedCompanyMerchant && { company_type: companyType }),
      },
    });

  const complete =
    !!uboSelfDeclared &&
    (isUSLimitedCompanyMerchant
      ? isListComplete(creditorType, businessOwners.control_prongs || [])
      : true) &&
    (isGcSasCompany
      ? (businessOwners.shareholders as ShareHolderType[]).every(
          (shareholder) => !!shareholder.place_of_birth
        )
      : true);

  return {
    loaded: !!creditorDetails,
    skip: !isSupported(
      creditor?.creditor_type ?? CreditorType.Company,
      creditor?.geo ?? CountryCodes.GB
    ),
    completed: complete,
    route: Route.BusinessOwners,
    businessOwners,
    countryCode,
    companyNumber,
    registeredCompanySearchId,
    achUpliftInitiated,
    submitOwners,
  };
}
