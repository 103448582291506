import { SetupData, SetupSteps } from "./types";

import SetupPages from "src/components/routes/Setup/common/constants/SetupPages";
import { Route } from "src/common/routing";

/**
 * Returns a list of active setup pages based
 * on if a feature flag is enabled
 *
 *
 * @param data
 * @param experiments
 * @returns
 */
export const getActiveSetupPages = (
  data: SetupData,
  experiments: { page: SetupPages; experimentEnabled: boolean }[]
) =>
  data.filter(
    ({ page, pageData: { skip } }) =>
      !skip &&
      !experiments.find(
        (experiment) =>
          page === experiment.page && !experiment.experimentEnabled
      )
  );

/**
 * Returns the list of steps required to
 * complete onboarding
 *
 * @param data
 * @param defaultRoute
 * @returns
 */
export const getSetupSteps = (
  data: SetupData,
  defaultRoute: Route
): SetupSteps =>
  data.map(({ page, pageData }) => ({
    page,
    route: pageData.route,
    // Account status page is completed if all other pages are completed
    completed: defaultRoute === Route.AccountStatus || pageData.completed,
  }));

/**
 * Determines whether a merchant should
 * be on a previous, next or default step
 *
 * @param data
 * @param setupPage
 * @param defaultPage
 * @returns
 */
export const getStep = (
  data: SetupSteps,
  setupPage: SetupPages,
  defaultPage?: SetupPages
): number =>
  data.findIndex(
    ({ page }) => page === (defaultPage ? defaultPage : setupPage)
  );

/**
 * Returns what should be the next and
 * previous route given where merchant is
 * in the flow
 *
 * @param setupSteps
 * @param currentStep
 * @returns
 */
export const getBackAndNextRoute = (
  setupSteps: SetupSteps,
  currentStep: number
) => ({
  backRoute:
    currentStep > 0
      ? setupSteps[currentStep - 1]?.route
      : Route.PackageSelection,
  nextRoute:
    currentStep < setupSteps.length - 1
      ? setupSteps[currentStep + 1]?.route
      : Route.AccountStatus,
});
