enum SetupPages {
  Index = "page.setup.index",
  PackageSelection = "page.setup.package-selection",
  AboutYou = "page.setup.about-you",
  BillingDetails = "page.setup.billing-details",
  BusinessDetails = "page.setup.business-details",
  MoreBusinessDetails = "page.setup.more-business-details",
  ContactDetails = "page.setup.contact-details",
  BusinessCategory = "page.setup.business-category",
  PersonActingOnBehalfOf = "page.setup.person-acting-on-behalf-of",
  BusinessDirectors = "page.setup.business-directors",
  BusinessOwners = "page.setup.business-owners",
  AchUpliftDetails = "page.setup.ach-uplift-details",
  BankDetails = "page.setup.bank-details",
  BankDetailsSelect = "page.setup.bank-details-select",
  VerifyBank = "page.setup.verify-bank",
  VerifyBankDocument = "page.setup.verify-bank-document",
  AccountStatus = "page.setup.account-status",
  BankStatementName = "page.setup.bank-statememt-name",
  TrusteeDetails = "page.setup.trustee-details",
  TrustOwners = "page.setup.trust-owners",
  PaymentVolumes = "page.setup.payment-volumes",
  PackageSelectionSecondaryStep = "page.setup.package-selection-secondary-step",
}

export default SetupPages;
