import {
  RequiredActionConfirmDepositResponseBody,
  useRequiredActionList,
} from "@gocardless/api/dashboard/required-action";
import {
  RequiredActionResource,
  RequiredActionsName,
  RequiredActionsStatus,
} from "@gocardless/api/dashboard/types";

import { useConfirmDeposit } from "src/libraries/verification/helpers";
import { Route } from "src/common/routing";
import { useBankDetails } from "src/components/routes/Setup/bank-details/hooks/useBankDetails";
import { UseSetupPage } from "src/components/routes/Setup/routing/types";
import { usePrimaryCreditor } from "src/libraries/creditor/hooks";

export interface UseVerifyBank extends UseSetupPage {
  requiredActions?: RequiredActionResource[];
  confirmDeposit: (
    creditorId: string,
    verificationId: string
  ) => Promise<RequiredActionConfirmDepositResponseBody>;
  refreshSetup: () => Promise<boolean>;
}

export const useVerifyBank = (): UseVerifyBank => {
  const { data, revalidate: refreshSetup } = useRequiredActionList();
  const { completed: bankDetailsCompleted } = useBankDetails();
  const confirmDeposit = useConfirmDeposit();
  const creditor = usePrimaryCreditor();
  const copVariantEnabled = creditor?.cop_enabled;

  const depositVerifications = data?.required_actions?.filter(
    (action) => action.name === RequiredActionsName.SendVerificationDeposit
  );

  // completed if all required verifications are successful, in review or deposit confirmed
  // A deposit verification can be in_review without deposit_confirmed if the penny test fails
  // and the merchant doesn't confirm they have sent the penny.
  const completed =
    !!depositVerifications?.length &&
    depositVerifications?.every(
      (action) =>
        action.entity?.deposit_confirmed ||
        action.status === RequiredActionsStatus.InReview ||
        action.status === RequiredActionsStatus.Successful
    );

  // skip if bank details completed, but no deposit verifications available or if COP check enabled
  // and verification status is successful or superseded.
  const skip =
    (bankDetailsCompleted && depositVerifications?.length === 0) ||
    (copVariantEnabled && completed);

  return {
    loaded: !!data?.required_actions,
    requiredActions: data?.required_actions,
    route: Route.VerifyBank,
    completed,
    confirmDeposit,
    refreshSetup,
    skip,
  };
};
