import _ from "lodash";
import { DD, DL, DLLayout, DT } from "@gocardless/flux-react";
import { createElement } from "react";
import { useLingui } from "@lingui/react";

import { Field as FieldType, Field } from "../common/config/types";
import { mapValue } from "../common/fields/helpers";

import { BusinessDetailsConfig } from "./types";

import { COUNTRY_CODES } from "src/common/constants/countryCodes";

const SingleDetail: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  const { i18n } = useLingui();
  return (
    <>
      <DT>{typeof label === "function" ? createElement(label) : label}</DT>
      <DD className="fs-mask">
        <>{mapValue(COUNTRY_CODES(i18n), "code", "name", value)}</>
      </DD>
    </>
  );
};

const ConcatenatedDetails: React.FC<{
  label: string;
  fields: Field[];
  data: BusinessDetailsConfig;
}> = ({ label, fields, data }) => (
  <>
    <DT>{label} </DT>
    <DD className="fs-mask">
      {fields
        .map((d) => _.get(data, d.name))
        .filter((x) => x)
        .join(", ")}
    </DD>
  </>
);

const CompanyDetails: React.FC<{
  data: BusinessDetailsConfig;
  fields: FieldType[];
}> = ({ data, fields }) => (
  <DL layout={[DLLayout.Stack, null, DLLayout.Row]}>
    {fields?.map((field, i) =>
      _.isArray(field.component) ? (
        <ConcatenatedDetails
          key={i}
          label={field.displayName}
          fields={field.component}
          data={data}
        />
      ) : (
        <SingleDetail
          key={i}
          label={field.displayName}
          value={_.get(data, field.name)}
        />
      )
    )}
  </DL>
);

export default CompanyDetails;
