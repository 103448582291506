import { ReactElement, useEffect } from "react";
import _, { get, hasIn } from "lodash";
import {
  Field,
  Label,
  Input,
  Hint,
  FormFieldStatus,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { isValid as isValidPostCode } from "postcode";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { I18n } from "@lingui/core";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";

import { Field as Config, FieldArrayProps } from "../config/types";

import { presenceCheck, validateAddressField } from "./helpers";

import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";

interface LabelConfig {
  label: string;
  labelElement: ReactElement;
  companyError: string;
  directorError: string;
  ownerError: string;
  personError: string;
}

export enum LocaleNameMap {
  US = "Zip code",
}

export const getLabel = (i18n: I18n, countryCode: string): LabelConfig => {
  const map = LocaleNameMap[countryCode as keyof typeof LocaleNameMap];
  return !_.isEmpty(map)
    ? {
        label: map,
        labelElement: <Trans id="Zip code">Zip code</Trans>,
        companyError: i18n._(
          t({
            id: "setup.zip-code.error-company",
            message: `Please enter the zip code for your company address`,
          })
        ),
        directorError: i18n._(
          t({
            id: "setup.zip-code.error-director",
            message: `Please enter director’s zip code`,
          })
        ),
        ownerError: i18n._(
          t({
            id: "setup.zip-code.error-owner",
            message: `Please enter owner's zip code`,
          })
        ),
        personError: i18n._(
          t({
            id: "setup.zip-code.error-person",
            message: `Please enter zip code`,
          })
        ),
      }
    : {
        label: "Post code",
        labelElement: <Trans id="Post code">Post code</Trans>,
        companyError: i18n._(
          t({
            id: "setup.post-code.error-company",
            message: `Please enter the post code for your company address`,
          })
        ),
        directorError: i18n._(
          t({
            id: "setup.post-code.error-director",
            message: `Please enter director’s post code`,
          })
        ),
        ownerError: i18n._(
          t({
            id: "setup.post-code.error-owner",
            message: `Please enter owner's post code`,
          })
        ),
        personError: i18n._(
          t({
            id: "setup.post-code.error-person",
            message: `Please enter post code`,
          })
        ),
      };
};

type PostalCodeFieldProps = {
  fieldPath: string;
  requiredError: string;
  countryCode: string;
  labelElement: ReactElement;
} & FieldArrayProps;

const PostalCodeField: React.FC<PostalCodeFieldProps> = ({
  defaultValue,
  fieldPath,
  countryCode,
  labelElement,
  requiredError,
}) => {
  const { i18n } = useLingui();
  const { isVariationOn: isNewPostcodeValidatorOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.VERIFICATIONS_NEW_POSTCODE_VALIDATOR,
  });

  const {
    register,
    formState: { errors },
    setValue,
    clearErrors: clearError,
  } = useFormContext();

  useEffect(() => {
    clearError(fieldPath);
    setValue(fieldPath, defaultValue);
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);
  const isGB = countryCode === "GB";

  const errorMessage = hasIn(errors, fieldPath)
    ? `${get(errors, `${fieldPath}`)?.message}`
    : undefined;

  const presenceCheckValidation = presenceCheck(requiredError);
  const validatePostCode = (v: string) => {
    if (isNewPostcodeValidatorOn) {
      if (postcodeValidatorExistsForCountry(countryCode)) {
        if (postcodeValidator(v, countryCode)) {
          return true;
        } else {
          return `${i18n._(
            t({
              message: "Please enter a valid postcode",
            })
          )}`;
        }
      } else {
        return true;
      }
    }

    if (!isGB || isValidPostCode(v)) {
      return true;
    }
    return `${i18n._(
      t({
        id: "setup.postal-code.error-gb",
        message: `Please enter a valid GB postcode`,
      })
    )}`;
  };
  const addressFormatValidation = validateAddressField(
    `${i18n._(
      t({
        id: "setup.about-you.address.postalcode",
        message: "Invalid input",
      })
    )}`
  );
  const validationRules = {
    ...presenceCheckValidation,
    validate: {
      ...presenceCheckValidation.validate,
      ...addressFormatValidation,
      validatePostCode,
    },
  };

  return (
    <Field>
      <Label htmlFor={fieldPath}>{labelElement}</Label>
      <Input
        id={fieldPath}
        defaultValue={defaultValue}
        {...register(fieldPath, validationRules)}
        className="fs-exclude"
      />
      {errorMessage && (
        <Hint status={FormFieldStatus.Danger}>{errorMessage}</Hint>
      )}
    </Field>
  );
};

export const CompanyPostalCodeField: React.FC<
  FieldArrayProps & { countryCode: string }
> = (props) => {
  const { i18n } = useLingui();
  const { labelElement, companyError } = getLabel(i18n, props.countryCode);

  return (
    <PostalCodeField
      {...props}
      labelElement={labelElement}
      fieldPath="postal_code"
      requiredError={companyError}
    />
  );
};

const DirectorPostalCodeField: React.FC<
  FieldArrayProps & { countryCode: string }
> = (props) => {
  const { i18n } = useLingui();
  const { labelElement, directorError } = getLabel(i18n, props.countryCode);
  return (
    <PostalCodeField
      {...props}
      labelElement={labelElement}
      fieldPath={`directors[${props.index}].postal_code`}
      requiredError={directorError}
    />
  );
};

const OwnerPostalCodeField: React.FC<
  FieldArrayProps & { countryCode: string }
> = (props) => {
  const { i18n } = useLingui();
  const { labelElement, ownerError } = getLabel(i18n, props.countryCode);
  return (
    <PostalCodeField
      {...props}
      labelElement={labelElement}
      fieldPath={`shareholders[${props.index}].postal_code`}
      requiredError={ownerError}
    />
  );
};

const PersonPostalCodeField: React.FC<
  FieldArrayProps & { countryCode: string }
> = (props) => {
  const { i18n } = useLingui();
  const { labelElement, personError } = getLabel(i18n, props.countryCode);
  return (
    <PostalCodeField
      {...props}
      labelElement={labelElement}
      fieldPath="person.postal_code"
      requiredError={personError}
    />
  );
};

const ControlPostalCodeField: React.FC<
  FieldArrayProps & { countryCode: string }
> = (props) => {
  const { i18n } = useLingui();
  const { labelElement, personError } = getLabel(i18n, props.countryCode);
  return (
    <PostalCodeField
      {...props}
      labelElement={labelElement}
      fieldPath={`control_prongs[${props.index}].postal_code`}
      requiredError={personError}
    />
  );
};

const TrusteePostalCodeField: React.FC<
  FieldArrayProps & { countryCode: string }
> = (props) => {
  const { i18n } = useLingui();
  const { labelElement, personError } = getLabel(i18n, props.countryCode);
  return (
    <PostalCodeField
      {...props}
      labelElement={labelElement}
      fieldPath={`trustees[${props.index}].postal_code`}
      requiredError={personError}
    />
  );
};

const PartnerPostalCodeField: React.FC<
  FieldArrayProps & { countryCode: string }
> = (props) => {
  const { i18n } = useLingui();
  const { labelElement, personError } = getLabel(i18n, props.countryCode);
  return (
    <PostalCodeField
      {...props}
      labelElement={labelElement}
      fieldPath={`partners[${props.index}].postal_code`}
      requiredError={personError}
    />
  );
};

const UBOPostalCodeField: React.FC<
  FieldArrayProps & { countryCode: string }
> = (props) => {
  const { i18n } = useLingui();
  const { labelElement, personError } = getLabel(i18n, props.countryCode);
  return (
    <PostalCodeField
      {...props}
      labelElement={labelElement}
      fieldPath={`ultimate_beneficial_owners[${props.index}].postal_code`}
      requiredError={personError}
    />
  );
};

export const companyConfig: Config = {
  name: "postal_code",
  displayName: "Postal code",
  required: true,
  component: CompanyPostalCodeField,
};

export const directorConfig: Config = {
  name: "postal_code",
  displayName: "Postal code",
  required: true,
  component: DirectorPostalCodeField,
};

export const ownerConfig: Config = {
  name: "postal_code",
  displayName: "Postal code",
  component: OwnerPostalCodeField,
};

export const personConfig: Config = {
  name: "postal_code",
  displayName: "Postal code",
  required: true,
  component: PersonPostalCodeField,
};

export const controlConfig: Config = {
  name: "postal_code",
  displayName: "Postal code",
  component: ControlPostalCodeField,
};

export const trusteeConfig: Config = {
  name: "postal_code",
  displayName: "Postal code",
  component: TrusteePostalCodeField,
};

export const partnerConfig: Config = {
  name: "postal_code",
  displayName: "Postal code",
  component: PartnerPostalCodeField,
};

export const uboConfig: Config = {
  name: "postal_code",
  displayName: "Postal code",
  component: UBOPostalCodeField,
};

export default PostalCodeField;
