import { Field, Label, Input } from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { CreditorType } from "@gocardless/api/dashboard/types";

import { Field as Config } from "../config/types";
import { BusinessCategoryConfig } from "../../business-category/useBusinessCategory";

import { error, presenceCheck } from "./helpers";

import isOrganisationCreditorType from "src/components/routes/Setup/common/helpers/isOrganisationCreditorType";

interface CategoryDescriptionFieldProps {
  creditorType: CreditorType;
}

const CategoryDescriptionField = ({
  creditorType,
}: CategoryDescriptionFieldProps) => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
  } = useFormContext<BusinessCategoryConfig>();

  const label = isOrganisationCreditorType(creditorType)
    ? i18n._(
        t({
          id: "setup.business-category.business-description-label-organisation",
          message: "What does your organisation do?",
        })
      )
    : i18n._(
        t({
          id: "setup.business-category.business-description-label",
          message: "What does your business do?",
        })
      );

  const placeholder = isOrganisationCreditorType(creditorType)
    ? i18n._(
        t({
          id: "setup.business-category.business-description-placeholder-organisation",
          message: "e.g. the nature and purpose of your organisation",
        })
      )
    : i18n._(
        t({
          id: "setup.business-category.business-description-placeholder",
          message: "e.g Provides Coffee Subscriptions",
        })
      );

  return (
    <Field>
      <Label htmlFor="description">{label}</Label>
      <Input
        {...register(
          "description",
          presenceCheck(
            i18n._(
              t({
                id: "setup.business-category.provide-business-description",
                message: "Please provide a description",
              })
            )
          )
        )}
        id="description"
        placeholder={placeholder}
      />
      {error(errors, "description")}
    </Field>
  );
};

export const config: Config = {
  name: "description",
  required: true,
  displayName: "Category Description",
  component: CategoryDescriptionField,
};

export default CategoryDescriptionField;
