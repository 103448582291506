import { ReactElement, useEffect } from "react";
import {
  Field,
  FormFieldStatus,
  Hint,
  Input,
  Label,
} from "@gocardless/flux-react";
import { Controller, useFormContext } from "react-hook-form";
import { t, Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { CreditorType } from "@gocardless/api/dashboard/types";

import { BusinessCategoryConfig } from "../../business-category/useBusinessCategory";
import { Field as Config, FieldArrayProps } from "../config/types";

import { useSegmentForSetup } from "src/components/routes/Setup/common/hooks/useSegmentForSetup";
import { TrackingEvent } from "src/common/trackingEvents";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";

interface SocialMediaFieldProps extends FieldArrayProps {
  labelElement?: ReactElement;
  creditorType: CreditorType;
}

const OnlinePresenceField: React.FC<SocialMediaFieldProps> = ({
  creditorType,
  labelElement,
}) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<BusinessCategoryConfig>();

  const { sendEvent } = useSegmentForSetup();

  const socialMediaErrorMessage = i18n._(
    t({
      message: "Please enter a valid URL e.g. https://instagram.com/gocardless",
    })
  );

  const { isFlagEnabled: socialMediaInputEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.SOCIAL_MEDIA_INPUT,
  });

  const socialMediaIsValid = (v: string) => {
    if (!v) {
      return true;
    }
    try {
      const uri = new URL(v);

      const SOCIAL_MEDIA_DOMAINS = [
        /^(?:www\.)?facebook\.com$/,
        /^(?:www\.)?instagram\.com$/,
        /^(?:www\.)?(?:[a-z]{2}\.)?linkedin\.com$/,
      ];

      const isSocialMedia = SOCIAL_MEDIA_DOMAINS.some((pattern) =>
        pattern.test(uri.hostname)
      );

      if (!isSocialMedia) {
        return socialMediaErrorMessage;
      }
    } catch {
      return socialMediaErrorMessage;
    }
    return true;
  };

  useEffect(() => {
    if (socialMediaInputEnabled) {
      sendEvent(TrackingEvent.MERCHANT_ONBOARDING_SOCIAL_MEDIA_ENABLED);
    } else {
      sendEvent(TrackingEvent.MERCHANT_ONBOARDING_SOCIAL_MEDIA_DISABLED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialMediaInputEnabled]);

  return (
    <>
      {socialMediaInputEnabled && (
        <Controller
          name="online_presence_url"
          rules={{
            validate: socialMediaIsValid,
          }}
          render={({ field }) => (
            <Field>
              <Label htmlFor="online_presence_url">
                {labelElement ?? <Trans>Social media (optional)</Trans>}
              </Label>
              <Hint>
                <Trans>
                  Link to the {creditorType} Facebook, Instagram or LinkedIn
                  page.
                </Trans>
              </Hint>
              <Input
                {...register(field.name)}
                name={field.name}
                id={field.name}
                className="fs-exclude"
                defaultValue={field.value}
                placeholder="https://"
              />
              {errors.online_presence_url && (
                <Hint status={FormFieldStatus.Danger}>
                  {errors.online_presence_url.message}
                </Hint>
              )}
            </Field>
          )}
        />
      )}
    </>
  );
};

export const config: Config = {
  name: "online_presence_url",
  displayName: <Trans>Social media (optional)</Trans>,
  component: OnlinePresenceField,
};

export default OnlinePresenceField;
