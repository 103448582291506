import _ from "lodash";
import { Hint, FormFieldStatus } from "@gocardless/flux-react";
import { FieldErrors, FieldValues } from "react-hook-form";
import {
  CreditorResource,
  DetailResource,
} from "@gocardless/api/dashboard/types";
import XRegExp from "xregexp";

import { Field as FieldType } from "src/components/routes/Setup/common/config/types";

export const error = <T extends FieldValues>(
  errors: FieldErrors<T>,
  name: keyof typeof errors
) =>
  errors[name] ? (
    <Hint status={FormFieldStatus.Danger}>
      {errors[name]?.message as string | undefined}
    </Hint>
  ) : null;

export const mapValue = (
  list: { [key: string]: {} }[],
  search: string,
  pick: string,
  value: string
) => {
  const mapped = list.find((d) => d[search] === value)?.[pick];
  return mapped ? mapped : value;
};

// @todo move to builders / replace
export const prefixKey = (
  data: CreditorResource | DetailResource,
  key: string,
  prefix: string
) => _.mapKeys(data, (_v, k) => (k === key ? `${prefix}_${k}` : k));

// @todo move to useConfig
export const flattenFieldArray = (fields: FieldType[]) =>
  fields.reduce(
    (fieldList: FieldType[], currentField) => [
      ...fieldList,
      ...(_.isArray(currentField.component)
        ? currentField.component
        : [currentField]),
    ],
    []
  );

export const presenceCheck = (requiredError: string) => ({
  required: requiredError,
  validate: {
    presence: (value?: string) => !!value?.trim() || requiredError,
  },
});

export const isValidUnicodeAddressString = (value: string) => {
  // This matches all valid alphanumeric letters, including letters with accents.
  // This prevents issues with users pasting in hidden chars and chars that
  // are unable to be UTF-8 encoded.

  const unicodeMatcher = XRegExp("^[\\pL\\pM0-9\\s\\.\\-,\\/,\\']+$");
  return unicodeMatcher.test(value);
};

export const hasValidApostrophe = (value: string) => value.indexOf("''") === -1;

export const validateAddressField = (errorMessage: string) => ({
  validUnicodeCharacters: (v?: string) => {
    if (_.isEmpty(v) || !v) {
      return true;
    }
    return (
      (isValidUnicodeAddressString(v) && hasValidApostrophe(v)) || errorMessage
    );
  },
});
