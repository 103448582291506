import { addressLookupCreate } from "@gocardless/api/dashboard/address-lookup";
import { AddressLookupCreateRequestBody } from "@gocardless/api/staff/types";
import { buildUrl } from "@gocardless/api/utils/build-url";
import { Endpoint } from "@gocardless/api/dashboard/common/endpoints";
import api from "@gocardless/api/utils/api";
import { AddressDetailSelfResponseBody } from "@gocardless/api/dashboard/types";

export interface FindAddressResult {
  id?: string;
  has_nested_results?: boolean;
  label: string;
}

export interface RetrieveAddressResult {
  address_line1?: string;
  address_line2?: string;
  address_line3?: string;
  flat_number?: string | null;
  building_number?: string | null;
  building_name?: string | null;
  street?: string | null;
  city?: string;
  region?: string | null;
  postal_code?: string;
  country_code?: string;
}

export interface RetrievePersonAddressResult {
  flat_number?: string | null;
  building_number?: string | null;
  building_name?: string | null;
  street?: string | null;
  city?: string;
  region?: string | null;
  postal_code?: string;
  country_code?: string;
}

const customError = (
  methodName: string,
  error: unknown,
  errorMessage: string
) => `${methodName}: ${errorMessage} - ${error}`;

export const findAddress = (params: AddressLookupCreateRequestBody) =>
  addressLookupCreate(params)
    .then(
      ({ address_lookups }) =>
        address_lookups?.map(
          ({
            id,
            address_text,
            description,
            has_nested_results,
          }): FindAddressResult => ({
            id: id,
            has_nested_results: has_nested_results,
            label: `${address_text}, ${description}`,
          })
        )
    )
    .catch((error) => {
      throw new Error(
        customError(findAddress.name, error, "Unable to find address")
      );
    });

export const retrieveAddress = (
  addressDetailId: string
): Promise<RetrieveAddressResult> => {
  const url = buildUrl({
    endpoint: Endpoint.AddressDetailSelf,
    endpointParams: { addressDetailId },
  });

  return api.API.get(url)
    .then((response): Promise<AddressDetailSelfResponseBody> => {
      if (response.status !== 200) {
        throw new Error("Address Find Call Error");
      }
      return response.json();
    })
    .then(
      ({ address_details }): RetrieveAddressResult => ({
        address_line1: address_details?.address_line1,
        address_line2: address_details?.address_line2,
        address_line3: address_details?.address_line3,
        flat_number: address_details?.flat_number,
        building_number: address_details?.building_number,
        building_name: address_details?.building_name,
        street: address_details?.street,
        city: address_details?.city,
        region: address_details?.region,
        postal_code: address_details?.postal_code,
        country_code: address_details?.country_code,
      })
    )
    .catch((error) => {
      throw new Error(
        customError(retrieveAddress.name, error, "Unable to retrieve address")
      );
    });
};

export const retrievePersonAddress = (
  addressDetailId: string
): Promise<RetrievePersonAddressResult> => {
  const url = buildUrl({
    endpoint: Endpoint.AddressDetailSelf,
    endpointParams: { addressDetailId },
  });

  return api.API.get(url)
    .then((response): Promise<AddressDetailSelfResponseBody> => {
      if (response.status !== 200) {
        throw new Error("Address Find Call Error");
      }
      return response.json();
    })
    .then(
      ({ address_details }): RetrievePersonAddressResult => ({
        flat_number: address_details?.address_line1,
        building_number: address_details?.building_number,
        building_name: address_details?.building_name,
        street: address_details?.street,
        city: address_details?.city,
        region: address_details?.region,
        postal_code: address_details?.postal_code,
        country_code: address_details?.country_code,
      })
    )
    .catch((error) => {
      throw new Error(
        customError(
          retrievePersonAddress.name,
          error,
          "Unable to retrieve person address"
        )
      );
    });
};
