import { get, hasIn } from "lodash";
import { useFormContext } from "react-hook-form";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Field,
  FormFieldStatus,
  Hint,
  Label,
  Option,
  Select,
} from "@gocardless/flux-react";

import { presenceCheck } from "./helpers";

import {
  Field as Config,
  FieldArrayProps,
} from "src/components/routes/Setup/common/config/types";
import { COUNTRY_CODES_WITHOUT_OVERSEAS_TERRITORIES } from "src/common/constants/countryCodes";

interface PlaceOfBirthFieldProps extends FieldArrayProps {
  fieldPath: string;
  requiredError: string;
  showHint?: boolean;
}

const PlaceOfBirthField: React.FC<PlaceOfBirthFieldProps> = ({
  defaultValue,
  fieldPath,
  requiredError,
  showHint = false,
}) => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Field>
      <Label htmlFor={fieldPath}>
        <Trans id="Place of birth">Place of birth</Trans>
      </Label>
      {showHint && (
        <Hint>
          <Trans id="setup.about-you.place-of-birth-hint">
            As it appears on official documents such as a passport
          </Trans>
        </Hint>
      )}
      <Select
        {...register(fieldPath, presenceCheck(requiredError))}
        id={fieldPath}
        defaultValue={defaultValue}
      >
        <Option key="default" value="">
          {t({
            id: "Please select",
            message: "Please select",
          })}
        </Option>
        {COUNTRY_CODES_WITHOUT_OVERSEAS_TERRITORIES(i18n).map(
          ({ code, name }) => (
            // eslint-disable-next-line react/jsx-no-undef
            <Option key={code} value={code}>
              {name}
            </Option>
          )
        )}
      </Select>
      {hasIn(errors, fieldPath) && (
        <Hint status={FormFieldStatus.Danger}>
          {get(errors, `${fieldPath}`)?.message as string}
        </Hint>
      )}
    </Field>
  );
};

const OwnerPlaceOfBirthField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();

  return (
    <PlaceOfBirthField
      {...props}
      fieldPath={`shareholders[${props.index}].place_of_birth`}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.enter-owner-place-of-birth",
          message: "Please enter owner's place of birth",
        })
      )}
    />
  );
};

const TrusteePlaceOfBirthField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();

  return (
    <PlaceOfBirthField
      {...props}
      fieldPath={`trustees[${props.index}].place_of_birth`}
      requiredError={i18n._(
        t({
          id: "setup.trustee-details.enter-place-of-birth",
          message: "Please enter place of birth",
        })
      )}
    />
  );
};

const PersonPlaceOfBirthField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();

  return (
    <PlaceOfBirthField
      {...props}
      fieldPath="person.place_of_birth"
      requiredError={i18n._(
        t({
          id: "setup.about-you.enter-place-of-birth",
          message: "Please enter place of birth",
        })
      )}
      showHint
    />
  );
};

const UBOPlaceOfBirthField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();

  return (
    <PlaceOfBirthField
      {...props}
      fieldPath={`ultimate_beneficial_owners[${props.index}].place_of_birth`}
      requiredError={i18n._(
        t({
          id: "setup.ubo-details.enter-ubo-place-of-birth",
          message: "Please enter place of birth",
        })
      )}
      showHint
    />
  );
};

export const ownerConfig: Config = {
  name: "place_of_birth",
  displayName: "Place of birth",
  component: OwnerPlaceOfBirthField,
};

export const trusteeConfig: Config = {
  name: "place_of_birth",
  displayName: "Place of birth",
  component: TrusteePlaceOfBirthField,
};

export const personConfig: Config = {
  name: "place_of_birth",
  displayName: "Place of birth",
  component: PersonPlaceOfBirthField,
};

export const uboConfig: Config = {
  name: "place_of_birth",
  displayName: "Place of birth",
  component: UBOPlaceOfBirthField,
};

export default PlaceOfBirthField;
