import {
  AlignItems,
  Aside,
  Box,
  ColorPreset,
  Glyph,
  Icon,
  Interpose,
  PlainButton,
  Separator,
  Sidebar,
  SidebarLayout,
  Space,
  Text,
  TypePreset,
  XYGrid,
} from "@gocardless/flux-react";
import router from "next/router";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import SetupPages from "src/components/routes/Setup/common/constants/SetupPages";
import { Route, routerPush } from "src/common/routing";
import stepLabels from "src/components/routes/Setup/common/constants/SetupPageLabels";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";

export interface SidebarProps {
  sideBarVisible: boolean;
  setupPage: SetupPages;
  setupSteps: { page: SetupPages; route: Route; completed: boolean }[];
  closeSidebar: () => void;
}

const SidebarComponent: React.FC<SidebarProps> = ({
  sideBarVisible,
  setupPage,
  setupSteps,
  closeSidebar,
}) => {
  const { i18n } = useLingui();
  const { sendEvent } = useSegment();

  return (
    <Box>
      <Aside>
        <Sidebar
          open={sideBarVisible}
          closeAction={{ onClose: closeSidebar, label: "close" }}
          layout={SidebarLayout.FloatingLeft}
          contentWidth="240px"
        >
          <Box gutterH={1}>
            <Box gutterV={1.5}>
              <Text preset={TypePreset.Heading_02}>
                <Trans id="setup.sidebar.account-set-up">Account set up</Trans>
              </Text>
            </Box>
            <Separator color={ColorPreset.BorderOnLight_03} />
            <Space v={1.5} />
            <Interpose node={<Space v={1.5} />}>
              {setupSteps.map(({ page, route, completed }, index) => {
                if (page === setupPage) {
                  return (
                    <PlainButton onClick={closeSidebar} key={index}>
                      <XYGrid
                        templateColumns="16px auto"
                        columnGap={0.5}
                        alignItems={AlignItems.Center}
                      >
                        <Icon name={Glyph.Subtract} size="16px" />
                        <Text preset={TypePreset.Body_01}>
                          {stepLabels(i18n, page)}
                        </Text>
                      </XYGrid>
                    </PlainButton>
                  );
                } else if (completed) {
                  return (
                    <PlainButton
                      onClick={async () => {
                        sendEvent(
                          TrackingEvent.MERCHANT_ONBOARDING_SIDEBAR_ITEM_CLICKED,
                          {
                            target: route,
                            page: router.pathname,
                          }
                        );

                        routerPush({ route });
                      }}
                      key={index}
                    >
                      <XYGrid
                        templateColumns="16px auto"
                        columnGap={0.5}
                        alignItems={AlignItems.Center}
                      >
                        <Icon name={Glyph.Tick} size="16px" />
                        <Text preset={TypePreset.Body_01}>
                          {stepLabels(i18n, page)}
                        </Text>
                      </XYGrid>
                    </PlainButton>
                  );
                } else {
                  return (
                    <XYGrid
                      templateColumns="16px auto"
                      columnGap={0.5}
                      alignItems={AlignItems.Center}
                      key={index}
                    >
                      <Space h={1} />
                      <Text
                        preset={TypePreset.Body_01}
                        color={ColorPreset.TextOnLight_03}
                      >
                        {stepLabels(i18n, page)}
                      </Text>
                    </XYGrid>
                  );
                }
              })}
            </Interpose>
          </Box>
        </Sidebar>
      </Aside>
    </Box>
  );
};

export default SidebarComponent;
