import { CreditorType } from "@gocardless/api/dashboard/types";
import {
  Banner,
  BannerStatus,
  P,
  BannerVariant,
  ButtonVariant,
  BannerLeftAccessoryType,
} from "@gocardless/flux-react";
import { ReactElement, useEffect } from "react";
import { Trans } from "@lingui/macro";

import { UnsupportedCreditorTypeBanner } from "../common/components/UnsupportedCreditorTypeBanner";

import { Field as Config } from "src/components/routes/Setup/common/config/types";
import { CountryCodes } from "src/common/country";
import { ZendeskLink } from "src/components/ui/ZendeskLink/ZendeskLink";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";

interface UnsupportedCreditorTypeProps {
  geo: string;
  creditorType: CreditorType;
}

export const COUNTRIES_THAT_SUPPORT_TRUSTS = [CountryCodes.AU];

export const COUNTRIES_THAT_SUPPORT_PARTNERSHIPS = [
  CountryCodes.AU,
  CountryCodes.CA,
  CountryCodes.DE,
];

export const COUNTRIES_THAT_SUPPORT_CHARITIES = [
  CountryCodes.DE,
  CountryCodes.ES,
  CountryCodes.FR,
  CountryCodes.GB,
  CountryCodes.IE,
];

const SUPPORTED_COUNTRIES_BY_CREDITOR_TYPE: {
  [type in CreditorType]?: string[];
} = {
  [CreditorType.Partnership]: COUNTRIES_THAT_SUPPORT_PARTNERSHIPS,
  [CreditorType.Trust]: COUNTRIES_THAT_SUPPORT_TRUSTS,
  [CreditorType.Charity]: COUNTRIES_THAT_SUPPORT_CHARITIES,
};

/**
 * Checks to see if the given geo
 * supports the chosen creditor type
 *
 * @param geo
 * @param creditorType
 * @returns
 */
const isSupported = (geo: string, creditorType: CreditorType) =>
  !!SUPPORTED_COUNTRIES_BY_CREDITOR_TYPE[creditorType]?.includes(geo);

const UnsupportedCreditorType: React.FC<UnsupportedCreditorTypeProps> = ({
  geo,
  creditorType,
}) => {
  const { sendEvent } = useSegment();

  const isSupportedType = isSupported(geo, creditorType);

  const title: Record<CreditorType, ReactElement> = {
    [CreditorType.Company]: (
      <Trans id="setup.business-details.unsuppported-creditor.unsupported-company">
        Please contact support to change your business type to limited company
      </Trans>
    ),
    [CreditorType.Charity]: (
      <Trans id="setup.business-details.unsuppported-creditor.unsupported-charity">
        Please contact support via the link below to complete account set up as
        a charity
      </Trans>
    ),
    [CreditorType.Individual]: (
      <Trans id="setup.business-details.unsuppported-creditor.unsupported-individual">
        Please contact support to change your business type to individual/sole
        trader
      </Trans>
    ),
    [CreditorType.Trust]: (
      <Trans id="setup.business-details.unsuppported-creditor.unsupported-true">
        Please contact support to change your business type to trust
      </Trans>
    ),
    [CreditorType.Partnership]: (
      <Trans id="setup.business-details.unsuppported-creditor.unsupported-partnership">
        Please contact support to change your business type to partnership
      </Trans>
    ),
  };

  useEffect(() => {
    sendEvent(TrackingEvent.SIGNUP_BUSINESS_TYPE_FIELD_UPDATED, {
      label: creditorType,
      error_type: isSupportedType
        ? "Please contact support"
        : "Unsupported business type",
      business_location: geo,
    });
  }, [creditorType, geo, isSupportedType, sendEvent]);

  return isSupportedType ? (
    <Banner
      variant={BannerVariant.Light}
      leftAccessory={{
        type: BannerLeftAccessoryType.Status,
        status: BannerStatus.Warning,
      }}
      title={title[creditorType]}
    >
      <P
        spaceBelow={0.5}
      >{`If your organisation has a company number, please choose 'Limited company' instead.`}</P>
      <ZendeskLink
        data-tracking-label="Unsupported creditor type"
        data-tracking-detail={`creditor_type - ${creditorType}`}
        formId={134125}
        variant={ButtonVariant.InlineUnderlined}
      >
        <Trans id="setup.business-details.unsuppported-creditor.contact-support">
          Contact support
        </Trans>
      </ZendeskLink>
    </Banner>
  ) : (
    <UnsupportedCreditorTypeBanner />
  );
};

export const config: Config = {
  name: "unsupported_creditor_type",
  displayName: "Unsupported Creditor Type",
  component: UnsupportedCreditorType,
};

export default UnsupportedCreditorType;
