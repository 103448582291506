import { get, pick } from "lodash";
import { useEffect, useState } from "react";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import {
  Box,
  Button,
  ButtonSize,
  ButtonVariant,
  Field,
  FormFieldStatus,
  Hint,
  Label,
  Text,
  TypePreset,
  FontWeight,
  Space,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";

import SearchInput from "../SearchInput";

import {
  findAddress,
  FindAddressResult,
  RetrieveAddressResult,
  RetrievePersonAddressResult,
} from "src/queries/addressSearch";
import { useToastNotification } from "src/hooks/useToastNotification";
import { captureException } from "src/technical-integrations/sentry/sentry";

export interface AddressConfig {
  address_line1?: null | string;
  address_line2?: null | string;
  address_line3?: null | string;
  flat_number?: null | string;
  building_number?: null | string;
  building_name?: null | string;
  street?: null | string;
  city?: string;
  region?: null | string;
  postal_code?: null | string;
  country_code?: string;
}

export const pickPersonAddress = (defaultValue: object): AddressConfig =>
  pick(defaultValue, [
    "flat_number",
    "building_number",
    "building_name",
    "street",
    "city",
    "region",
    "postal_code",
    "country_code",
  ]);

interface AddressSearchProps {
  country_code: string;
  onSelectAddress: (fullAddress: AddressConfig) => void;
  onChooseManualOption: () => void;
  retrieveAddress: (
    id: string
  ) => Promise<RetrieveAddressResult | RetrievePersonAddressResult>;
  fieldPath: string;
  fieldMessage: string;
  description?: string;
  index: number;
}

const AddressSearch: React.FC<AddressSearchProps> = ({
  country_code,
  onSelectAddress,
  onChooseManualOption,
  retrieveAddress,
  fieldPath,
  fieldMessage,
  description,
  index,
}) => {
  const { i18n } = useLingui();
  const {
    register,
    unregister,
    formState: { errors },
  } = useFormContext();
  const addressQueryRegister = `${fieldPath}-${index}`;

  useEffect(
    () => () => {
      unregister(addressQueryRegister);
    },
    [unregister, addressQueryRegister]
  );
  const [addressQuery, setAddressQuery] = useState("");
  const [addressResults, setAddressResults] = useState<FindAddressResult[]>([]);
  const [hasShownErrorNotification, setHasShownErrorNotification] =
    useState(false);
  const { triggerErrorNotification } = useToastNotification();

  const setErrorNotification = () => {
    if (!hasShownErrorNotification) {
      triggerErrorNotification({
        message: (
          <Trans>
            We are unable to retrieve the selected address. Please enter your
            address manually instead
          </Trans>
        ),
      });
    }
  };

  const handleError = (error: Error) => {
    setErrorNotification();
    setHasShownErrorNotification(true);
    onChooseManualOption();
    captureException({ error });
  };

  const updateAddressResults = (
    addressSearchText: string,
    containerId?: string
  ): void => {
    if (addressSearchText.length < 3) {
      setAddressResults([]);
    } else {
      findAddress({
        search_term: addressSearchText,
        id: containerId,
        country_code: country_code,
      })
        .then((results) => setAddressResults(results ?? []))
        .catch((error) => {
          handleError(error);
        });
    }
  };

  const selectAddressItem = ({ id, has_nested_results }: FindAddressResult) => {
    if (!has_nested_results && id) {
      retrieveAddress(id)
        .then(onSelectAddress)
        .catch((error) => {
          handleError(error);
        });
    } else {
      updateAddressResults(addressQuery, id);
    }
  };

  const descriptionHint = (text: string | undefined, path: string) => {
    if (text) return <Hint id={`${path}.description`}>{text}</Hint>;
    return;
  };

  return (
    <Box>
      <Field>
        <Label htmlFor={fieldPath}>{fieldMessage}</Label>
        {descriptionHint(description, fieldPath)}
        <Space v={0.5} />
        <SearchInput<FindAddressResult>
          placeholder={i18n._(
            t({
              id: "setup.address.placeholder",
              message: "Start typing and select address",
            })
          )}
          searchResults={addressResults.map((addressItem) => ({
            key: addressItem.id as string,
            ...addressItem,
          }))}
          searchComplete={addressResults.length > 0}
          renderSearchResult={({ label }) => (
            <Box gutterH={0.75} gutterV={0.75} css={{ textAlign: "start" }}>
              <Text size={2} className="fs-mask">
                {label}
              </Text>
            </Box>
          )}
          renderEnterManually={() => (
            <Text size={2}>
              <Trans id="setup.address.cannot-find">
                Can&apos;t find your address?
              </Trans>{" "}
              <Button
                variant={ButtonVariant.InlineUnderlined}
                onClick={onChooseManualOption}
                size={ButtonSize.Sm}
              >
                <Trans id="setup.search.enter-details-manually">
                  Enter your details manually
                </Trans>
              </Button>
            </Text>
          )}
          onSearchResultSelected={selectAddressItem}
          {...register(addressQueryRegister, {
            onChange(event) {
              setAddressQuery(event.target.value);
              updateAddressResults(event.target.value);
            },
            required: i18n._(
              t({
                id: "setup.address-search-required-error",
                message:
                  "Please search for your address or enter your details manually",
              })
            ),
          })}
          className="fs-exclude"
        />
        {errors.addressQuery && (
          <Hint status={FormFieldStatus.Danger}>
            {get(errors, "addressQuery")?.message as string}
          </Hint>
        )}
        <Space v={0.5} />
        <Button
          size={ButtonSize.Sm}
          variant={ButtonVariant.InlineUnderlined}
          onClick={onChooseManualOption}
        >
          <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
            <Trans id="setup.address.or-enter-manually">
              Or enter address manually
            </Trans>
          </Text>
        </Button>
      </Field>
    </Box>
  );
};

export default AddressSearch;
