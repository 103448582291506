import _ from "lodash";
import { CreditorDetailResource } from "@gocardless/api/dashboard/types";

import {
  BusinessDirectorsConfig,
  DirectorType,
} from "../../business-directors/useBusinessDirectors";

import {
  ADDRESS_FIELDS,
  BASIC_PERSON_FIELDS,
  INDIVIDUAL_NUMBER_FIELDS,
} from "src/components/routes/Setup/common/builders/types";

/**
 * Builds a correctly formatted object for the
 * Business Directors form from the creditor_details
 * API response
 *
 * @param creditorDetails
 * @returns
 */
export const getDirectors = (
  creditorDetails?: CreditorDetailResource
): DirectorType[] => {
  const directors = creditorDetails?.detail?.directors || [];
  return directors.map((director) =>
    _.pick(_.omitBy(director, _.isNull), [
      ...BASIC_PERSON_FIELDS,
      ...INDIVIDUAL_NUMBER_FIELDS,
      ...ADDRESS_FIELDS,
    ])
  );
};

/**
 * Builds a correctly formatted business directors
 * object for the creditor_details put request body
 *
 * @param directorInfo
 * @param country_code
 * @returns
 */
export const formatDirectorsBuilder = (
  directorInfo: BusinessDirectorsConfig,
  country_code: string
) =>
  directorInfo.directors.map((director) =>
    director.street && director.city && director.postal_code
      ? director
      : {
          ..._.pick(director, ["given_name", "family_name", "date_of_birth"]),
          country_code,
        }
  );
