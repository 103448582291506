import _ from "lodash";
import {
  AmlUboPersonWithAddressResource,
  CreditorDetailResource,
  CreditorType,
} from "@gocardless/api/dashboard/types";

import {
  BusinessOwnersConfig,
  ControlProngType,
  ShareHolderType,
  ultimateBeneficialOwnerType,
} from "../../business-owners/useBusinessOwners";

import {
  ADDRESS_FIELDS,
  BASIC_PERSON_FIELDS,
  INDIVIDUAL_NUMBER_FIELDS,
} from "src/components/routes/Setup/common/builders/types";

/**
 * Builds a correctly formatted object for the
 * Business Owners form from the creditor_details
 * API response
 *
 * @param creditorDetails
 * @returns
 */
export const ownersBuilder = (creditorDetails?: CreditorDetailResource) => {
  if (!creditorDetails) return {};
  const creditorType = _.get(creditorDetails, "creditor_type");
  const ubos = getUbos(creditorDetails);

  if (creditorType === CreditorType.Partnership) {
    return {
      shareholders: ubos,
      ownerAvailable: ubos.length ? "yes" : "no",
    };
  }
  const controlProngs = getControlProngs(creditorDetails);
  const shareholders = getShareholders(creditorDetails);
  return {
    ultimateBeneficialOwners: ubos,
    shareholders,
    control_prongs: controlProngs,
    ownerAvailable: shareholders.length ? "yes" : "no",
  };
};

/**
 * Builds a correctly formatted business owners
 * object for creditor_details put request body
 *
 * @param owners
 * @param countryCode
 * @param creditorType
 * @returns
 */
export const formatOwnersBuilder = (
  owners: BusinessOwnersConfig,
  countryCode: string,
  creditorType: CreditorType | null | undefined
) => {
  const formShareholders = _.get(owners, "shareholders", []).map(
    (shareholder: ShareHolderType) => ({
      country_code: countryCode,
      ...shareholder,
    })
  );
  const formControlProngs = _.get(owners, "control_prongs", []);
  // For Partnerships use shareholders to build ultimate_beneficial_owners
  return creditorType === CreditorType.Partnership
    ? {
        ultimate_beneficial_owners:
          formShareholders as AmlUboPersonWithAddressResource[],
      }
    : {
        shareholders: formShareholders,
        control_prongs:
          formControlProngs.length > 0 ? formControlProngs : undefined,
      };
};

/**
 * Extract UBO details from
 * creditor_details API response
 *
 * @param creditorDetails
 * @returns
 */
export const uboSelfDeclaredBuilder = (
  creditorDetails: CreditorDetailResource
) =>
  _.get(creditorDetails, "detail.ultimate_beneficial_owners_self_declared_at");

/**
 * Extract shareholders (UBOs) from
 * creditor_details API response
 *
 * @param creditorDetails
 * @returns
 */
const getShareholders = (
  creditorDetails: CreditorDetailResource
): ShareHolderType[] => {
  const shareholders = creditorDetails.detail?.shareholders || [];
  return shareholders.map((shareholder) =>
    _.assign(
      _.pick(_.omitBy(shareholder, _.isNull), [
        ...BASIC_PERSON_FIELDS,
        ...INDIVIDUAL_NUMBER_FIELDS,
        ...ADDRESS_FIELDS,
      ]),
      {
        date_of_birth: buildDate(
          shareholder["year_of_birth"],
          shareholder["month_of_birth"],
          shareholder["day_of_birth"]
        ),
      }
    )
  );
};

/**
 * Extract the control prong (ACH) from
 * creditor_details API response
 *
 * @param creditorDetails
 * @returns
 */
const getControlProngs = (
  creditorDetails: CreditorDetailResource
): ControlProngType[] => {
  const control_prongs = creditorDetails.detail?.control_prongs || [];
  return control_prongs.map((controlProng) =>
    _.pick(_.omitBy(controlProng, _.isNull), [
      ...BASIC_PERSON_FIELDS,
      ...INDIVIDUAL_NUMBER_FIELDS,
      ...ADDRESS_FIELDS,
    ])
  );
};

const buildDate = (
  yearOfBirth?: number | null,
  monthOfBirth?: number | null,
  dayOfBirth?: number | null
): string => {
  const dateOfBirth = [
    `${yearOfBirth ? `${yearOfBirth}-` : ""}`,
    `${monthOfBirth ? `${monthOfBirth < 10 ? "0" : ""}${monthOfBirth}-` : ""}`,
    `${dayOfBirth ? `${dayOfBirth < 10 ? "0" : ""}${dayOfBirth}` : ""}`,
  ];
  return dateOfBirth.join("");
};

/**
 * Extract UBOs for partnerships from
 * creditor_details API response
 *
 * @param creditorDetails
 * @returns
 */
export const getUbos = (
  creditorDetails: CreditorDetailResource
): ultimateBeneficialOwnerType[] => {
  const ubos = creditorDetails.detail?.ultimate_beneficial_owners || [];

  return ubos.map((ubo) =>
    _.pick(_.omitBy(ubo, _.isNull), [
      ...BASIC_PERSON_FIELDS,
      ...ADDRESS_FIELDS,
      "provider_name",
      "country_code",
    ])
  );
};
