import {
  AmlPersonWithAddressResource,
  CreditorDetailResource,
  CreditorType,
  TrusteeElement,
  UserResource,
} from "@gocardless/api/dashboard/types";

import { CharityResource, IndividualResource } from "../types/about-you";

import {
  AboutYouConfig,
  IndividualPersonType,
} from "src/components/routes/Setup/about-you/useAboutYou";

const getPerson = (
  amlPerson: AmlPersonWithAddressResource | undefined,
  countryCode: string,
  user?: UserResource
): IndividualPersonType => ({
  given_name: amlPerson?.given_name || user?.given_name || "",
  family_name: amlPerson?.family_name || user?.family_name || "",
  date_of_birth:
    amlPerson?.date_of_birth || user?.aml_entity?.date_of_birth || "",
  place_of_birth: amlPerson?.place_of_birth || user?.aml_entity?.place_of_birth,
  flat_number: amlPerson?.flat_number,
  building_number: amlPerson?.building_number,
  building_name: amlPerson?.building_name,
  street: amlPerson?.street || "",
  city: amlPerson?.city || "",
  region: amlPerson?.region || undefined,
  postal_code: amlPerson?.postal_code || "",
  country_code: amlPerson?.country_code || countryCode,
});

export const parseAboutYou = (
  creditorDetails: CreditorDetailResource | undefined,
  user: UserResource | undefined,
  countryCode: string
): AboutYouConfig => {
  if (creditorDetails?.creditor_type === CreditorType.Charity) {
    const signatories = creditorDetails?.detail?.signatories;
    return {
      person: getPerson(signatories && signatories[0], countryCode, user),
      account_holder_is_trustee:
        creditorDetails?.detail?.account_holder_is_trustee || false,
      creditor_type: creditorDetails.creditor_type,
      charity_type: creditorDetails.detail?.charity_type,
    };
  }

  return {
    person: getPerson(creditorDetails?.detail?.person, countryCode, user),
    individual_number: creditorDetails?.detail?.individual_number || undefined,
    individual_number_type:
      creditorDetails?.detail?.individual_number_type || undefined,
    creditor_type: creditorDetails?.creditor_type,
  };
};

export function aboutYouBuilder(
  creditorType: CreditorType
): AboutYouBuilderFunction {
  if (creditorType === CreditorType.Individual) {
    return individualAboutYouBuilder;
  }
  if (creditorType === CreditorType.Charity) {
    return charityAboutYouBuilder;
  }

  throw new Error(
    `about you builder is not supported for creditor type "${creditorType}"`
  );
}

type AboutYouBuilderFunction = (
  data: AboutYouConfig,
  country_code: string,
  trustees?: TrusteeElement[]
) => CharityResource | IndividualResource;

const individualAboutYouBuilder: AboutYouBuilderFunction =
  function individualAboutYouBuilder(
    {
      individual_number = null,
      individual_number_type = null,
      person: {
        given_name,
        family_name,
        date_of_birth,
        flat_number,
        building_number,
        building_name,
        street,
        city,
        region,
        postal_code,
        place_of_birth,
      },
    },
    country_code
  ) {
    return {
      individual_number,
      individual_number_type,
      person: {
        given_name,
        family_name,
        date_of_birth,
        flat_number,
        building_number,
        building_name,
        street,
        city,
        region,
        postal_code,
        country_code,
        place_of_birth,
      },
    };
  };

const charityAboutYouBuilder: AboutYouBuilderFunction =
  function charityAboutYouBuilder(data, _country_code, trustees) {
    const { account_holder_is_trustee = false } = data;
    const {
      given_name,
      family_name,
      date_of_birth,
      place_of_birth,
      // country of residence of the person, which may
      // be different from the business country code
      country_code,
      flat_number,
      building_number,
      building_name,
      street,
      city,
      region,
      postal_code,
    } = data.person;

    // If account holder is trustee,
    // we need to add the details into the trustees field
    // while keeping the existing trustees.
    const newTrustees = account_holder_is_trustee
      ? [
          {
            given_name,
            family_name,
            date_of_birth,
            place_of_birth,
            country_code,
          },
          ...(trustees && trustees.length > 0
            ? trustees.filter(
                (t) =>
                  // exclude the matching signatory trustee
                  !(
                    t.given_name === given_name &&
                    t.family_name === family_name &&
                    t.date_of_birth === date_of_birth
                  )
              )
            : []),
        ]
      : trustees;

    return {
      signatories: [
        {
          given_name,
          family_name,
          date_of_birth,
          place_of_birth,
          country_code,
          flat_number,
          building_number,
          building_name,
          street,
          city,
          region,
          postal_code,
        },
      ],
      account_holder_is_trustee,
      trustees: newTrustees,
    };
  };
