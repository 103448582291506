import { useCreditorDetailSelf } from "@gocardless/api/dashboard/creditor-detail";

import { usePartnerMerchantShouldSkipPrimaryPackageSelection } from "../../../useShouldShowSecondaryPackageSelectionStep/useShouldShowSecondaryPackageSelectionStep";

import { usePrimaryCreditor } from "src/libraries/creditor/hooks";
import { useOrganisation } from "src/libraries/organisation/hooks";
import { Route, useRouterQuery } from "src/common/routing";
import { UseSetupPage } from "src/components/routes/Setup/routing/types";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";

export interface UsePackageSelectionSecondaryStep extends UseSetupPage {}

export const usePackageSelectionSecondaryStep =
  (): UsePackageSelectionSecondaryStep => {
    const organisation = useOrganisation();

    const creditor = usePrimaryCreditor();
    const { data: creditorDetails } = useCreditorDetailSelf(
      creditor?.id || null
    );

    const skipPackageSelection = useRouterQuery("skip_package_selection").item;

    const { isVariationOn: packageSelectionExperimentEnabled } =
      useOptimizelyVariation({
        flag: OptimizelyFlag.PACKAGE_SELECTION_PHASE_ONE,
      });

    const { shouldSkipSecondary: partnerShouldSkipSecondaryPackageSelection } =
      usePartnerMerchantShouldSkipPrimaryPackageSelection({
        organisation,
      });

    const completed =
      !!skipPackageSelection ||
      !!organisation?.organisation_preferences
        ?.merchant_has_requested_package ||
      !!localStorage.getItem(`gc.package.${organisation?.id}`);

    //hide this page from all non partner or partner merchants that haven't skipped package selection/ have selected a package
    //including !packageSelectionSkipped hides the item from the side menu until the merchant has explicity skipped
    const skip =
      !packageSelectionExperimentEnabled ||
      (packageSelectionExperimentEnabled &&
        partnerShouldSkipSecondaryPackageSelection);

    return {
      loaded: !!creditorDetails && !!organisation,
      completed,
      route: Route.PackageSelectionSecondaryStep,
      skip,
    };
  };
