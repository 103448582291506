import { Dispatch, SetStateAction } from "react";
import { useFormContext } from "react-hook-form";
import {
  Field,
  Label,
  Option,
  Banner,
  BannerVariant,
  BannerStatus,
  P,
  Space,
  Hint,
  FormFieldStatus,
  Select,
  BannerLeftAccessoryType,
} from "@gocardless/flux-react";
import { CharityType } from "@gocardless/api/dashboard/types";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { get, hasIn } from "lodash";

import { Field as Config } from "../config/types";

import { CountryCodes } from "src/common/country";
import { usePaobVerification } from "src/components/routes/Setup/common/hooks/usePaobVerification";

enum SupportedCountries {
  DE = CountryCodes.DE,
  ES = CountryCodes.ES,
  FR = CountryCodes.FR,
  GB = CountryCodes.GB,
  IE = CountryCodes.IE,
}

const supportedCharityTypesByGeo: {
  [key in SupportedCountries]: CharityType[];
} = {
  [CountryCodes.DE]: [
    CharityType.RegisteredCharity,
    CharityType.UnregisteredSportsClub,
  ],
  [CountryCodes.ES]: [CharityType.RegisteredCharity],
  [CountryCodes.FR]: [CharityType.RegisteredCharity],
  [CountryCodes.GB]: [
    CharityType.RegisteredCharity,
    CharityType.ExceptedScoutOrGuideGroup,
    CharityType.UnregisteredSportsClub,
    CharityType.Casc,
    CharityType.Other,
  ],
  [CountryCodes.IE]: [
    CharityType.RegisteredCharity,
    CharityType.UnregisteredSportsClub,
  ],
};

const CharityTypeField: React.FC<{
  geo: SupportedCountries;
  setCharityType: Dispatch<SetStateAction<CharityType>>;
}> = ({ geo, setCharityType }) => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { gcSasVerificationEnabled } = usePaobVerification();

  const supportedCharityTypes = gcSasVerificationEnabled
    ? [CharityType.RegisteredCharity, CharityType.UnregisteredSportsClub]
    : supportedCharityTypesByGeo[geo];
  const options = {
    [CharityType.RegisteredCharity]: (
      <Option
        key={CharityType.RegisteredCharity}
        value={CharityType.RegisteredCharity}
      >
        {i18n._(
          t({
            id: "setup.business-details.registered-charity",
            message: "Registered Charity",
          })
        )}
      </Option>
    ),
    [CharityType.ExceptedScoutOrGuideGroup]: (
      <Option
        key={CharityType.ExceptedScoutOrGuideGroup}
        value={CharityType.ExceptedScoutOrGuideGroup}
      >
        {i18n._(
          t({
            id: "setup.business-details.expected-charity",
            message: "Excepted charity (eg. Scout or Guide group)",
          })
        )}
      </Option>
    ),
    [CharityType.UnregisteredSportsClub]: (
      <Option
        key={CharityType.UnregisteredSportsClub}
        value={CharityType.UnregisteredSportsClub}
      >
        {i18n._(
          t({
            id: "setup.business-details.unregistered-charity",
            message: "Unregistered club or society",
          })
        )}
      </Option>
    ),
    [CharityType.Casc]: (
      <Option key={CharityType.Casc} value={CharityType.Casc}>
        {i18n._(
          t({
            id: "setup.business-details.community-ammateur",
            message: "Community amateur sports club (CASC)",
          })
        )}
      </Option>
    ),
    [CharityType.Other]: (
      <Option key={CharityType.Other} value={CharityType.Other}>
        {i18n._(
          t({
            id: "setup.business-details.other-charity",
            message: "Other",
          })
        )}
      </Option>
    ),
  };

  const invalidCharityTypeErrorMessage = i18n._(
    t({
      id: "setup.business-details.charity-type-not-supported",
      message: "Charity type is not supported",
    })
  );

  return (
    <>
      <Banner
        variant={BannerVariant.Light}
        leftAccessory={{
          type: BannerLeftAccessoryType.Status,
          status: BannerStatus.Info,
        }}
        title=""
      >
        <P spaceBelow={0.5}>
          <Trans id="setup.business-details.charity-type-company-number-info">
            If your organisation has a company number, please choose
            &apos;Limited company&apos; instead.
          </Trans>
        </P>
      </Banner>
      <Space v={2} />
      <Field>
        <Label htmlFor="charity_type">
          <Trans id="setup.business-details.charity-type-label">
            Charity Type
          </Trans>
        </Label>
        <Select
          id="charity_type"
          {...register("charity_type", {
            required: true,
            validate: (charityType) =>
              gcSasVerificationEnabled
                ? charityType !== CharityType.RegisteredCharity
                  ? invalidCharityTypeErrorMessage
                  : true
                : true,
            onChange(event) {
              setCharityType(event.target.value as CharityType);
            },
          })}
        >
          {supportedCharityTypes.map((charityType) => options[charityType])}
        </Select>
        {hasIn(errors, "charity_type") && (
          <Hint status={FormFieldStatus.Danger}>
            <>{get(errors, `charity_type.message`)}</>
          </Hint>
        )}
      </Field>
    </>
  );
};

export const config: Config = {
  name: "charity_type",
  required: true,
  displayName: "Charity type",
  component: CharityTypeField,
};

export default CharityTypeField;
