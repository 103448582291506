import { get, hasIn } from "lodash";
import { useFormContext } from "react-hook-form";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Field,
  Label,
  Input,
  Hint,
  FormFieldStatus,
} from "@gocardless/flux-react";
import { TrusteeType } from "@gocardless/api/dashboard/types";

import { presenceCheck } from "./helpers";

import {
  Field as Config,
  FieldArrayProps,
} from "src/components/routes/Setup/common/config/types";

type FamilyNameFieldProps = {
  fieldPath: string;
  requiredError: string;
} & FieldArrayProps;

const FamilyNameField: React.FC<FamilyNameFieldProps> = ({
  defaultValue,
  fieldPath,
  requiredError,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <Field>
      <Label htmlFor={fieldPath}>
        <Trans id="Last Name">Last name</Trans>
      </Label>
      <Input
        {...register(fieldPath, presenceCheck(requiredError))}
        name={fieldPath}
        id={fieldPath}
        defaultValue={defaultValue}
        className="fs-exclude"
      />
      {hasIn(errors, fieldPath) && (
        <Hint status={FormFieldStatus.Danger}>
          {get(errors, `${fieldPath}`)?.message as string}
        </Hint>
      )}
    </Field>
  );
};

const DirectorFamilyNameField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <FamilyNameField
      {...props}
      fieldPath={`directors[${props.index}].family_name`}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.enter-last-name",
          message: "Please enter director’s last name",
        })
      )}
    />
  );
};

const OwnerFamilyNameField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <FamilyNameField
      {...props}
      fieldPath={`shareholders[${props.index}].family_name`}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.enter-owner-last-name",
          message: "Please enter owner’s last name",
        })
      )}
    />
  );
};

const ControlFamilyNameField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <FamilyNameField
      {...props}
      fieldPath={`control_prongs[${props.index}].family_name`}
      requiredError={i18n._(
        t({
          id: "setup.business-directors.enter-person-last-name",
          message: "Please enter last name",
        })
      )}
    />
  );
};

const PersonFamilyNameField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <FamilyNameField
      {...props}
      fieldPath="person.family_name"
      requiredError={i18n._(
        t({
          id: "setup.business-directors.enter-person-last-name",
          message: "Please enter last name",
        })
      )}
    />
  );
};

const TrusteeFamilyNameField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <FamilyNameField
      {...props}
      fieldPath={`trustees[${props.index}].family_name`}
      requiredError={i18n._({
        id: "setup.business-directors.enter-person-last-name",
        message: "Please enter last name",
      })}
    />
  );
};

const PartnerFamilyNameField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <FamilyNameField
      {...props}
      fieldPath={`partners[${props.index}].family_name`}
      requiredError={i18n._({
        id: "setup.business-directors.enter-person-last-name",
        message: "Please enter last name",
      })}
    />
  );
};

const UBOFamilyNameField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <FamilyNameField
      {...props}
      fieldPath={`ultimate_beneficial_owners[${props.index}].family_name`}
      requiredError={i18n._({
        id: "setup.business-directors.enter-person-last-name",
        message: "Please enter last name",
      })}
    />
  );
};

export const directorConfig: Config = {
  name: "family_name",
  displayName: "Last Name",
  required: true,
  component: DirectorFamilyNameField,
};

export const ownerConfig: Config = {
  name: "family_name",
  displayName: "Last Name",
  component: OwnerFamilyNameField,
};

export const controlConfig: Config = {
  name: "family_name",
  displayName: "Last Name",
  required: true,
  component: ControlFamilyNameField,
};
export const personConfig: Config = {
  name: "family_name",
  displayName: "Last Name",
  component: PersonFamilyNameField,
};

export const trusteeConfig: Config = {
  name: "family_name",
  displayName: "Last Name",
  required: ({ type }) => !type || type === TrusteeType.Person,
  component: TrusteeFamilyNameField,
};

export const partnerConfig: Config = {
  name: "family_name",
  displayName: "Last Name",
  required: true,
  component: PartnerFamilyNameField,
};

export const uboConfig: Config = {
  name: "family_name",
  displayName: "Last Name",
  required: true,
  component: UBOFamilyNameField,
};

export default FamilyNameField;
