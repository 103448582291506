import { Trans } from "@lingui/macro";
import {
  AlignItems,
  Box,
  Button,
  ButtonVariant,
  ButtonSize,
  ColorPreset,
  FontWeight,
  Interpose,
  JustifyContent,
  Label,
  Space,
  Text,
  TypePreset,
  Hint,
} from "@gocardless/flux-react";
import { get } from "lodash";

import { AddressConfig } from "./AddressSearch";

import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";

interface AddressCardProps {
  address?: AddressConfig;
  onChooseManualOption: () => void;
  onChooseSearchOption: () => void;
  addressFieldsToDisplayOnCard: string[];
  fieldPath: string;
  fieldMessage: string;
  description?: string;
  countryCode: string;
}

const AddressCard: React.FC<AddressCardProps> = ({
  address,
  countryCode,
  onChooseManualOption,
  onChooseSearchOption,
  addressFieldsToDisplayOnCard,
  fieldPath,
  fieldMessage,
  description,
}) => {
  const descriptionHint = (text: string | undefined, path: string) => {
    if (text) return <Hint id={`${path}.description`}>{text}</Hint>;
    return;
  };
  const { sendEvent } = useSegment();

  const sendManualAddressEditEvent = () => {
    sendEvent(TrackingEvent.MERCHANT_ONBOARDING_MANUAL_ADDRESS_EDIT, {
      country_code: countryCode,
    });
  };
  return (
    <Interpose node={<Space v={0.5} />}>
      <Label htmlFor={fieldPath}>{fieldMessage}</Label>
      {descriptionHint(description, fieldPath)}
      <Space v={0.5} />
      <Box
        gutterH={2}
        gutterV={2}
        bg={ColorPreset.BackgroundLight_01}
        borderWidth={1}
        borderColor={ColorPreset.BorderOnLight_05}
        borderRadius={1}
      >
        <Box
          layout="flex"
          justifyContent={JustifyContent.SpaceBetween}
          alignItems={AlignItems.FlexStart}
        >
          <Text size={3} weight={FontWeight.SemiBold} className="fs-mask">
            {addressFieldsToDisplayOnCard.map(
              (field) => get(address, field) && `${get(address, field)}, `
            )}
            <br />
            {/* city, region and postal_code are always displayed */}
            {address?.city && `${address.city}, `}
            {address?.region && `${address.region}, `}
            {address?.postal_code && `${address.postal_code}.`}
          </Text>
          <Button
            onClick={() => {
              onChooseManualOption();
              sendManualAddressEditEvent();
            }}
            variant={ButtonVariant.InlineUnderlined}
            size={ButtonSize.Sm}
          >
            <Text preset={TypePreset.Body_02} weight={FontWeight.SemiBold}>
              <Trans id="Edit">Edit </Trans>
            </Text>
          </Button>
        </Box>
      </Box>
      <Button
        size={ButtonSize.Sm}
        variant={ButtonVariant.InlineUnderlined}
        onClick={onChooseSearchOption}
      >
        <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
          <Trans id="setup.address.search-again">
            Search for address again
          </Trans>
        </Text>
      </Button>
    </Interpose>
  );
};

export default AddressCard;
