import { useEffect } from "react";
import {
  Field,
  FormFieldStatus,
  Hint,
  Label,
  Option,
  Select,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { get, hasIn } from "lodash";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { Field as Config, FieldArrayProps } from "../config/types";
import { states } from "../constants/states";

import { presenceCheck } from "./helpers";

import { CountryCodes } from "src/common/country";

type RegionFieldProps = {
  fieldPath: string;
  requiredError: string;
  required?: boolean;
} & FieldArrayProps;

const RegionField: React.FC<RegionFieldProps> = ({
  defaultValue,
  fieldPath,
  requiredError,
  required,
}) => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
    setValue,
    unregister,
  } = useFormContext();

  useEffect(
    () => () => {
      if (!required) unregister(fieldPath);
    },
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setValue(fieldPath, defaultValue);
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <Field>
      <Label htmlFor={fieldPath}>State</Label>
      <Select
        {...register(fieldPath, presenceCheck(requiredError))}
        id={fieldPath}
        defaultValue={defaultValue}
        className="fs-exclude"
      >
        <Option value="" disabled>
          {i18n._(
            t({
              id: "setup.select-state",
              message: "Select state",
            })
          )}
        </Option>
        {Object.entries(states).map((v, i) => (
          <Option data-testid="select-option" key={i} value={v[0]}>
            {v[1]}
          </Option>
        ))}
      </Select>
      {hasIn(errors, fieldPath) && (
        <Hint status={FormFieldStatus.Danger}>
          {get(errors, `${fieldPath}`)?.message as string}
        </Hint>
      )}
    </Field>
  );
};

export const CompanyRegionField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <RegionField
      {...props}
      fieldPath="region"
      requiredError={i18n._(
        t({
          id: "setup.error.please-select-state",
          message: "Please select a state",
        })
      )}
    />
  );
};

const DirectorRegionField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <RegionField
      {...props}
      fieldPath={`directors[${props.index}].region`}
      requiredError={i18n._(
        t({
          id: "setup.error.please-enter-director-region",
          message: "Please enter director’s region",
        })
      )}
    />
  );
};

const US_MILITARY_ADDRESS_STATES = ["AA", "AE", "AP"];

const ControlRegionField: React.FC<FieldArrayProps> = (props) => {
  const { defaultValue } = props;
  const { i18n } = useLingui();
  let requiredError = i18n._(t`Please select a state`);
  if (defaultValue && US_MILITARY_ADDRESS_STATES.includes(defaultValue)) {
    requiredError = i18n._(t`State ${defaultValue} is not supported`);
  }
  return (
    <RegionField
      {...props}
      fieldPath={`control_prongs[${props.index}].region`}
      requiredError={requiredError}
    />
  );
};

const OwnerRegionField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <RegionField
      {...props}
      fieldPath={`shareholders[${props.index}].region`}
      requiredError={i18n._(
        t({
          id: "setup.error.please-enter-owner-region",
          message: "Please enter owner’s region",
        })
      )}
    />
  );
};

const PersonRegionField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <RegionField
      {...props}
      fieldPath="person.region"
      requiredError={i18n._(
        t({
          id: "setup.error.please-enter-region",
          message: "Please enter region",
        })
      )}
    />
  );
};

const UBORegionField: React.FC<FieldArrayProps> = (props) => {
  const { i18n } = useLingui();
  return (
    <RegionField
      {...props}
      fieldPath={`ultimate_beneficial_owners[${props.index}].region`}
      requiredError={i18n._(
        t({
          id: "setup.error.please-enter-owner-region",
          message: "Please enter owner’s region",
        })
      )}
    />
  );
};

export const companyConfig: Config = {
  name: "region",
  required: true,
  displayName: "State",
  component: CompanyRegionField,
};

export const directorConfig: Config = {
  name: "region",
  required: ({ geo }) => geo === CountryCodes.US,
  displayName: "State",
  component: DirectorRegionField,
};

export const controlConfig: Config = {
  name: "region",
  required: false,
  displayName: "State",
  component: ControlRegionField,
};

export const ownerConfig: Config = {
  name: "region",
  required: false,
  displayName: "State",
  component: OwnerRegionField,
};

export const personConfig: Config = {
  name: "region",
  required: false,
  displayName: "State",
  component: PersonRegionField,
};

export const uboConfig: Config = {
  name: "region",
  required: false,
  displayName: "State",
  component: UBORegionField,
};

export default RegionField;
