import _ from "lodash";
import {
  CreditorResource,
  Resource,
  CreditorType,
  CreditorDetailResource,
  DetailResource,
  CharityType,
} from "@gocardless/api/dashboard/types";

import { prefixKey } from "../fields/helpers";
import { BusinessDetailsConfig } from "../../business-details/types";
import { BusinessDetailsFormVariant } from "../../business-details/BusinessDetailsForm";

/**
 * Builds a correctly formatted object for the
 * Business Details Form from the creditor
 * and creditor_details API responses
 *
 * @param creditor_type
 * @param creditor
 * @param creditorDetails
 * @param attrs
 * @returns
 */
export const businessDetailsBuilder = (
  creditor_type: CreditorType | null | undefined,
  creditor: CreditorResource | null | undefined,
  creditorDetails: CreditorDetailResource | undefined,
  attrs: string[]
): BusinessDetailsConfig => {
  const result = {
    ...prefixKey(_.pick(creditor, attrs), "name", "trading"),
    ...prefixKey(
      _.pick(creditorDetails?.detail, attrs),
      "name",
      creditor_type === CreditorType.Charity ? "charity" : "legal"
    ),
    creditor_type,
    scheme_identifiers: creditor?.scheme_identifiers,
  };

  // Read trading_name from creditor name as not all creditors have synced trading_name in creditor details
  if (creditor_type !== CreditorType.Individual) {
    return { ...result, trading_name: creditor?.name };
  }

  return result;
};

/**
 * Builds a correctly formatted company
 * creditor_details put request body
 *
 * @param data
 * @param registeredBusinessSearchId
 * @returns
 */
export const companyBuilder = (
  data: BusinessDetailsConfig,
  registeredBusinessSearchId: string | null = null,
  registeredBusinessSearchProvider: string | null = null
): Resource => ({
  name: data.legal_name,
  country_code: data.geo,
  ..._.pick(data, [
    "trading_name",
    "company_number",
    "company_type",
    "address_line1",
    "address_line2",
    "address_line3",
    "city",
    "region",
    "postal_code",
    "tax_number",
    "tax_jurisdiction",
    "online_presence_url",
  ]),
  registered_business_search_id: registeredBusinessSearchId,
  registered_business_search_provider: registeredBusinessSearchProvider,
});

/**
 * Builds a correctly formatted individual / sole trader
 * creditor details put request body
 *
 * @param data
 * @returns
 */
export const individualBuilder = (
  data: BusinessDetailsConfig,
  variant?: BusinessDetailsFormVariant
): Resource => {
  const fieldsToPick = [
    "trading_name",
    "tax_number",
    "tax_jurisdiction",
    "entity_number",
    "entity_number_type",
    "online_presence_url",
  ];

  if (variant && variant === BusinessDetailsFormVariant.Intro) {
    fieldsToPick.push("person");
  }

  return {
    country_code: data.geo,
    ..._.pick(data, fieldsToPick),
  };
};

const registeredCharityBuilder = (data: BusinessDetailsConfig) =>
  data.charity_type === CharityType.RegisteredCharity
    ? {
        charity_number: data.charity_number,
      }
    : {
        // If a merchant changes from RegisteredCharity to another charity type,
        // we need to make sure that registered charity specifc fields are empty
        // otherwise the backend will raise an error.
        charity_number: "",
        trustees: [],
      };

/**
 * Builds a correctly formatted charity
 * creditor_details put request body
 *
 * @param data
 * @returns
 */
export const charityBuilder = (data: BusinessDetailsConfig): Resource => ({
  name: data.charity_name,
  country_code: data.geo,
  ..._.pick(data, [
    "trading_name",
    "charity_type",
    "charity_number",
    "address_line1",
    "address_line2",
    "address_line3",
    "city",
    "postal_code",
    "region",
    "tax_jurisdiction",
    "tax_number",
    "online_presence_url",
  ]),
  ...registeredCharityBuilder(data),
});

/**
 * Builds a correctly formatted object to
 * submit to Segment if a merchant has
 * manually updated their Business Details
 *
 * @param data
 * @param prev
 * @returns
 */
export const businessDetailsEditedEventBuilder = (
  {
    legal_name,
    trading_name,
    company_number,
    address_line1,
    address_line2,
    address_line3,
    city,
    postal_code,
    tax_jurisdiction,
  }: BusinessDetailsConfig,
  prev: DetailResource | null | undefined
) => ({
  legal_name_updated: prev?.name !== legal_name,
  trading_name_updated: prev?.trading_name !== trading_name,
  company_number_updated: prev?.company_number !== company_number,
  registered_address_updated: _.some([
    prev?.address_line1 !== address_line1,
    prev?.address_line2 !== address_line2,
    prev?.address_line3 !== address_line3,
    prev?.city !== city,
    prev?.postal_code !== postal_code,
  ]),
  vat_country_updated: prev?.tax_jurisdiction !== tax_jurisdiction,
});

/**
 * Builds a correctly formatted partnership
 * creditor_details put request body
 *
 * @param data
 * @returns
 */
export const partnershipBuilder = (data: BusinessDetailsConfig): Resource => ({
  name: data.legal_name,
  country_code: data.geo,
  ..._.pick(data, [
    "trading_name",
    "partnership_type",
    "partnership_number",
    "address_line1",
    "address_line2",
    "address_line3",
    "city",
    "postal_code",
    "region",
    "online_presence_url",
  ]),
});

/**
 * Builds a correctly formatted trust
 * creditor_details put request body
 *
 * @param data
 * @returns
 */
export const trustBuilder = (data: BusinessDetailsConfig): Resource => ({
  name: data.legal_name,
  country_code: data.geo,
  ..._.pick(data, [
    "trading_name",
    "trust_number",
    "address_line1",
    "address_line2",
    "address_line3",
    "city",
    "postal_code",
    "region",
    "online_presence_url",
  ]),
});
