import { Field, Label, Input } from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { PartnershipType } from "@gocardless/api/dashboard/types";

import { Field as Config } from "../config/types";
import { BusinessDetailsConfig } from "../../business-details/types";

import { error } from "src/components/routes/Setup/common/fields/helpers";

const AUPartnershipNumberField = () => {
  const { register } = useFormContext<BusinessDetailsConfig>();
  return (
    <Field>
      <Label htmlFor="partnership_number">
        <Trans id="setup.business-detrails.australian-business-number">
          Australian Business Number (optional)
        </Trans>
      </Label>
      <Input
        id="partnership_number"
        placeholder="e.g. 12 345 678 901"
        {...register("partnership_number", { required: false })}
        className="fs-exclude"
      />
    </Field>
  );
};

const CAPartnershipNumberField = () => {
  const { register } = useFormContext<BusinessDetailsConfig>();
  return (
    <Field>
      <Label htmlFor="partnership_number">
        <Trans id="setup.business-detrails.canada-revenue-agency-number">
          Canada Revenue Agency (CRA) Number
        </Trans>
      </Label>
      <Input
        id="partnership_number"
        placeholder="e.g. 123456789 RC0001"
        {...register("partnership_number", { required: false })}
        className="fs-exclude"
      />
    </Field>
  );
};

const DEPartnershipNumberField = () => {
  const { i18n } = useLingui();
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  const requiredError = i18n._(
    t({
      id: "setup.business-details.partnership-number-required",
      message: "Please enter a valid partnership number",
    })
  );

  return (
    <Field>
      <Label htmlFor="partnership_number">
        <Trans id="setup.business-details.german-partnership-number">
          Partnership number
        </Trans>
      </Label>
      <Input
        id="partnership_number"
        placeholder="e.g. DE345889003"
        {...register("partnership_number", {
          required: false,
          validate: {
            presence: (value?: string) => {
              if (getValues().partnership_type !== PartnershipType.Gbr) {
                return !!value?.trim() || requiredError;
              } else {
                return true;
              }
            },
          },
        })}
        className="fs-exclude"
      />
      {error(errors, "partnership_number")}
    </Field>
  );
};

export const AUConfig: Config = {
  name: "partnership_number",
  displayName: "Partnership number",
  component: AUPartnershipNumberField,
};

export const CAConfig: Config = {
  name: "partnership_number",
  displayName: "Partnership number",
  component: CAPartnershipNumberField,
};

export const DEConfig: Config = {
  name: "partnership_number",
  displayName: "Partnership number",
  required: ({ partnership_type }) => partnership_type !== PartnershipType.Gbr,
  component: DEPartnershipNumberField,
};

export default DEPartnershipNumberField;
