import { useEffect } from "react";
import { get, hasIn } from "lodash";
import { useFormContext } from "react-hook-form";
import {
  Field,
  Label,
  Input,
  Hint,
  FormFieldStatus,
} from "@gocardless/flux-react";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";

import {
  Field as Config,
  FieldArrayProps,
} from "src/components/routes/Setup/common/config/types";
import {
  INVALID_SOCIAL_SECURITY_NUMBER_REGEX,
  VALID_ITIN_INVALID_SOCIAL_SECURITY_NUMBER_REGEX,
  VALID_SOCIAL_SECURITY_NUMBER_REGEX,
} from "src/utils/inputValidation";

type SocialSecurityFieldProps = {
  fieldPath: string;
  shouldUnregister?: boolean;
  personName?: string;
} & FieldArrayProps;

const SocialSecurityField: React.FC<SocialSecurityFieldProps> = ({
  defaultValue,
  fieldPath,
  shouldUnregister = true,
  personName,
}) => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
    unregister,
  } = useFormContext();
  useEffect(() => {
    if (shouldUnregister) {
      return () => unregister(fieldPath);
    }
    return;
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Field>
      <Label htmlFor={fieldPath}>
        {personName ? (
          <Trans id="social-security-number-for">
            Social Security Number for {personName}
          </Trans>
        ) : (
          <Trans id="social-security-number">Social Security Number</Trans>
        )}
      </Label>
      <Input
        {...register(fieldPath, {
          required: i18n._(
            t({
              id: "setup.error.please-enter-valid-social-security-number",
              message: "Please enter a social security number",
            })
          ),
          validate: (number) =>
            !VALID_SOCIAL_SECURITY_NUMBER_REGEX.test(number) ||
            INVALID_SOCIAL_SECURITY_NUMBER_REGEX.test(number) ||
            VALID_ITIN_INVALID_SOCIAL_SECURITY_NUMBER_REGEX.test(number)
              ? i18n._(
                  t({
                    id: "setup.error.please-enter-social-security-number",
                    message: "Please enter a valid social security number",
                  })
                )
              : true,
        })}
        id={fieldPath}
        defaultValue={defaultValue}
        className="fs-exclude"
      />
      {hasIn(errors, fieldPath) && (
        <Hint status={FormFieldStatus.Danger}>
          {get(errors, `${fieldPath}`)?.message as string}
        </Hint>
      )}
    </Field>
  );
};

const DirectorSocialSecurityField: React.FC<FieldArrayProps> = (props) => (
  <SocialSecurityField
    {...props}
    fieldPath={`directors[${props.index}].social_security_number`}
  />
);

const ControlSocialSecurityField: React.FC<FieldArrayProps> = (props) => (
  <SocialSecurityField
    {...props}
    fieldPath={`control_prongs[${props.index}].social_security_number`}
  />
);

const OwnerSocialSecurityField: React.FC<FieldArrayProps> = (props) => (
  <SocialSecurityField
    {...props}
    fieldPath={`shareholders[${props.index}].social_security_number`}
  />
);

const AchPersonSocialSecurityField: React.FC<FieldArrayProps> = (props) => (
  <SocialSecurityField {...props} fieldPath="person.social_security_number" />
);

const PersonSocialSecurityField: React.FC<FieldArrayProps> = (props) => (
  <SocialSecurityField
    {...props}
    fieldPath="individual_number"
    shouldUnregister={false}
  />
);

export const directorConfig: Config = {
  name: "social_security_number",
  displayName: "Social Security Number",
  component: DirectorSocialSecurityField,
};

export const controlConfig: Config = {
  name: "social_security_number",
  displayName: "Social Security Number",
  component: ControlSocialSecurityField,
};

export const ownerConfig: Config = {
  name: "social_security_number",
  displayName: "Social Security Number",
  component: OwnerSocialSecurityField,
};

export const personConfig: Config = {
  name: "social_security_number",
  displayName: "Social Security Number",
  component: PersonSocialSecurityField,
};

export const achPersonConfig: Config = {
  name: "social_security_number",
  displayName: "Social Security Number",
  component: AchPersonSocialSecurityField,
};

export default SocialSecurityField;
