import { Trans } from "@lingui/macro";
import { Box, Checkbox, ColorPreset, Field } from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { ReactNode } from "react";

import { Field as Config } from "../config/types";
import { AboutYouConfig } from "../../about-you/useAboutYou";
import { useSegmentForSetup } from "../hooks/useSegmentForSetup";

import { MerchantOnboardingSetupEvents } from "src/components/routes/Setup/common/constants/MerchantOnboardingSetupEvents";

interface AccountHolderIsTrusteeFieldProps {
  defaultChecked: boolean;
  displayName: ReactNode;
}

const AccountHolderIsTrusteeField: React.FC<
  AccountHolderIsTrusteeFieldProps
> = ({ defaultChecked, displayName }) => {
  const { sendEvent } = useSegmentForSetup();
  const { register } = useFormContext<AboutYouConfig>();

  return (
    <Field>
      <Box
        bg={ColorPreset.BackgroundLight_03}
        borderWidth={1}
        borderColor={ColorPreset.BorderOnLight_03}
        borderRadius={1}
        gutterH={2}
        gutterV={2}
      >
        <Checkbox
          {...register("account_holder_is_trustee", {
            onChange(e) {
              sendEvent(
                MerchantOnboardingSetupEvents.VerificationAccountHolderIsTrusteeChanged,
                {
                  account_holder_is_trustee: e.currentTarget.checked,
                }
              );
            },
          })}
          id="accountHolderIsTrustee"
          defaultChecked={defaultChecked}
        >
          {displayName}
        </Checkbox>
      </Box>
    </Field>
  );
};

export const config: Config = {
  name: "account_holder_is_trustee",
  displayName: (
    <Trans id="setup.about-you.account-holder-is-trustee">
      I am a trustee of this charity
    </Trans>
  ),
  component: AccountHolderIsTrusteeField,
};

export const sepaConfig: Config = {
  name: "account_holder_is_trustee",
  displayName: (
    <Trans id="setup.about-you.account-holder-is-beneficial-owner">
      I am a beneficial owner of this charity
    </Trans>
  ),
  component: AccountHolderIsTrusteeField,
};

export default AccountHolderIsTrusteeField;
